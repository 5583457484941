import React, { useEffect, useState } from "react";
import { Box, Button, HStack, Input, Text } from "@chakra-ui/react";
import { ModalCloseButton, ModalContent, ModalFooter } from "@chakra-ui/react";
import { Modal, ModalBody, ModalHeader, ModalOverlay } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { postOtpApi } from "../../../redux/ecommerceSlice";
import { updateEmail, userProfile } from "../../../redux/ecommerceSlice";

export const EmailOTP = ({ isOpen, onClose, email }) => {
  const [sentOTP, setSentOTP] = useState(false);
  const [inputeOTP, setInputeOTP] = useState(false);

  const ecommerce = useSelector((state) => state.ecommerce);
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      otp: "",
      new_email: "",
      old_email: "",
    },
    onSubmit: (values) => {
      const arrangeValues = {
        otp: values.otp,
        old_email: values.old_email,
        new_email: values.new_email,
      };
      dispatch(updateEmail(arrangeValues));
      onClose(true);
    },
  });

  return (
    <Box w="50%" ml={"5rem"}>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <form onSubmit={formik.handleSubmit}>
            <ModalHeader>Change Email</ModalHeader>
            <ModalCloseButton border={"none"} bg="gray.300" />
            <ModalBody mt="1rem">
              <Box>
                <Box mt="1rem">
                  <Text fontWeight={"400"} fontSize="lg" mb="0.5rem">
                    Please enter your email, to sent the OTP ?
                  </Text>
                  <Input
                    placeholder="Enter mail"
                    id="new_email"
                    type="text"
                    value={formik.values.new_email}
                    onChange={formik.handleChange}
                  />

                  {sentOTP && (
                    <Box fontSize={"sm"} color="green.600" fontWeight={"500"}>
                      If you didn't get the OTP in your email , please click
                      resent.
                    </Box>
                  )}
                </Box>
                <HStack mt="0.5rem">
                  <Button
                    border={"none"}
                    bg="gray.400"
                    ml="18rem"
                    w="7rem"
                    onClick={() => {
                      setInputeOTP(true);
                      setSentOTP(true);
                      dispatch(postOtpApi({ email: formik.values.new_email }));
                    }}
                  >
                    {sentOTP ? "Resent OTP" : "Sent OTP"}
                  </Button>
                </HStack>
              </Box>
              {inputeOTP && (
                <Box>
                  <Box mt="1rem">
                    <Text fontWeight={"400"} fontSize="lg" mb="0.5rem">
                      Enter OTP
                    </Text>
                    <Input
                      id="otp"
                      type={"number"}
                      value={formik.values.otp}
                      onChange={formik.handleChange}
                    />
                  </Box>
                  <Box mt="1rem">
                    <Text fontWeight={"400"} fontSize="lg" mb="0.5rem">
                      Enter Old Email
                    </Text>
                    <Input
                      placeholder="Enter mail"
                      id="old_email"
                      type="text"
                      value={formik.values.old_Email}
                      // value={email}
                      // onChange={(e) => setemail(e.target.value)}
                      onChange={formik.handleChange}
                    />
                  </Box>
                </Box>
              )}
            </ModalBody>
            <ModalFooter mt="1rem">
              <Button
                color={"#F7FAFC"}
                colorScheme="blue"
                mt="2.5%"
                size="md"
                border={"none"}
                fontSize="1.2rem"
                cursor={"pointer"}
                type="submit"
              >
                <Box
                  onClick={() => {
                    dispatch(userProfile());
                  }}
                >
                  Change Email
                </Box>
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </Box>
  );
};
