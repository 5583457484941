import { Box, Button, Center, Flex, HStack } from "@chakra-ui/react";
import { Image, Input, Radio, RadioGroup, Stack, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import { FcGoogle } from "react-icons/fc";
import { FaFacebook } from "react-icons/fa";
import { Form, useFormik } from "formik";
import { useDispatch } from "react-redux";
import * as Yup from "yup";

import {
  googleLogin,
  postOtpApi,
  signUpInfo,
} from "../../redux/ecommerceSlice";
import { SignupForm } from "./SignupForm";
import { useSelector } from "react-redux";
import { OtaPopup } from "./OtaPopup";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Nav from "../../components/Nav";
import GoogleLogin from "react-google-login";
import NavSideBar from "../../components/NavSideBar";

export const Signup = () => {
  const data = useSelector((state) => state.ecommerce.Otp_Api);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const authed = useSelector((state) => state.ecommerce.authenticate);
  const [formError, setformError] = useState(true); // this state is used for showing for otaPopup after validation is done sucessfully

  const validationSchema = Yup.object({
    full_name: Yup.string()
      .min(3, "Too Short!")
      .max(50, "Too Long!")
      .required("Please enter the Full name"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Please enter your email"),
    phone: Yup.number().required("Please enter your Phone Number"),
    gender: Yup.string().required("Please select one option"),
    password: Yup.string()
      .min(3, "Too Short!")
      .max(50, "Too Long!")
      .required("No password provided."),
  });
  const formik = useFormik({
    initialValues: {
      full_name: "",
      email: "",
      phone: "",
      gender: "",
      password: "",
    },
    validationSchema: validationSchema,
    validateOnChange: false,
    onSubmit: (values) => {
      // console.log(values);
      dispatch(postOtpApi({ email: values.email }));
      dispatch(signUpInfo(values));
      setformError(false);
      // console.log("message", data?.message);
      // console.log(formError);
    },
  });
  const responseGoogle = (response) => {
    // console.log(response);
    dispatch(googleLogin({ accessToken: response.accessToken }));
  };
  if (authed) {
    // navigate("/profile");
    navigate(state?.path || "/user/profile");
  }
  return (
    <>
      <NavSideBar />
      <Box position={"relative"}>
        <Box bg="black">
          <Image
            // boxSize={"100%"}
            src="./MingaDavid.png"
            width="100vw"
            height="102vh"
            objectFit="cover"
            opacity="0.25"
          />
        </Box>

        <Box
          bg="rgba(12, 0, 0, 0.600)"
          width="85%"
          height="90%"
          position={"absolute"}
          top="5%"
          left="7%"
          borderRadius={"2rem"}
          // opacity="0.63"
          // right="30%"
        >
          <Center color={"#F7FAFC"} fontSize="2rem" fontWeight={"bold"} mt="7%">
            WELCOME TO MINGMA DAVID SHERPA
          </Center>
          <Center color={"#F7FAFC"} fontSize="1rem" mt="0.5%">
            Login to your account and explore your Merch details.
          </Center>
          <form onSubmit={formik.handleSubmit}>
            <SignupForm formik={formik} formError={formError} />
          </form>

          <Center mt="2%">
            <Flex gap={"5rem"}>
              <Box w="20rem" h="0.1rem" bg="white"></Box>
              <Box w="20rem" h="0.1rem" bg="white"></Box>
            </Flex>
          </Center>
          <Center mt="3rem">
            <GoogleLogin
              clientId="778133011402-gjor8f3kho0bm72q20shb1j5tjb0dgks.apps.googleusercontent.com"
              buttonText="Sign up with google"
              onSuccess={responseGoogle}
              onFailure={responseGoogle}
              cookiePolicy={"single_host_origin"}
            />
          </Center>
          <Center color={"#F7FAFC"} fontSize="2xl" mt="1.5rem">
            Already have an account?{" "}
            <Text fontWeight={"bold"} ml="0.3rem">
              <NavLink to="/login">LOGIN</NavLink>
            </Text>
          </Center>
        </Box>
      </Box>
    </>
  );
};
