import { Image } from '@chakra-ui/react';
import Prev from '../assets/img/prev.png'

export default function PrevArrow(props){
    const { className, style, onClick } = props;
    return (
        <>
            <Image position={'fixed'} margin={'265px 100px'} zIndex={100} height={'70px'} width={'90px'} src={Prev} onClick={onClick} style={style} className={className} />
        </>
    )
}