import { Box, Grid, GridItem } from "@chakra-ui/react";
import { Center, Text, Image } from "@chakra-ui/react";

import { AiOutlineShoppingCart } from "react-icons/ai";
import { IoReorderThreeOutline } from "react-icons/io5";
import { useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import Logo from "../assets/img/icons/logo.png";
import Account from "../assets/img/icons/account-white.png";
import { createStandaloneToast } from "@chakra-ui/react";
import { useEffect } from "react";
import { useDisclosure } from "@chakra-ui/react";
import React from "react";

export default function HomeNavBar() {
  const state = useSelector((state) => state.ecommerce);
  const toast = createStandaloneToast();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();

  useEffect(() => {
    document.querySelectorAll(".chakra-alert").forEach((el) => {
      el.style.setProperty("font-size", "16px", "important");
      el.style.setProperty("padding", "10px", "important");
    });
  });

  return (
    <Box
      // boxShadow="base"
      pos="fixed"
      color={"white"}
      // bg={"#000000b5"}
      width={"100%"}
      zIndex={200}
      height="7rem"
    >
      <Grid gap={4} templateColumns="repeat(16, 1fr)" fontSize={"16px"}>
        <GridItem
          alignSelf={"center"}
          colStart={{ base: 2, md: 2, sm: 2 }}
          mt="1rem"
        >
          <NavLink to="/">
            <Image src={Logo} height={"4.5rem"} />
          </NavLink>
        </GridItem>

        <GridItem
          colStart={{ base: 14, md: 14, sm: 14 }}
          alignItems={"center"}
          colSpan={1}
          alignSelf={"center"}
          textAlign={"right"}
        >
          <Link to="/merch" className="nav-link" textAlign={"right"}>
            Shop
          </Link>{" "}
        </GridItem>

        <GridItem alignSelf={"center"} textAlign={"right"}>
          <Link to="/qr" className="nav-link">
            contact
          </Link>
        </GridItem>
      </Grid>
    </Box>
  );
}
