import React, { useState } from "react";
import { Box, Button, HStack, Input, Modal, ModalBody } from "@chakra-ui/react";
import { ModalCloseButton, ModalContent, ModalFooter } from "@chakra-ui/react";
import { ModalHeader, ModalOverlay, Text } from "@chakra-ui/react";

import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { InputField } from "./InputField";
import { forgetPassword, postOtpApi } from "../redux/ecommerceSlice";

export const ForgetPassword = ({ isOpen, onClose }) => {
  const [passwordInput, setPasswordInput] = useState(false);
  const [email, setEmail] = useState();
  const [passwordNotMatch, setPasswordNotMatch] = useState(false);
  const [sentOTP, setSentOTP] = useState(false);

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      otp: "",
      password: "",
      newPassword: "",
    },
    onSubmit: (values) => {
      // console.log("values", values);
      if (values.password === values.newPassword) {
        dispatch(
          forgetPassword({
            email,
            new_password: values.newPassword,
            otp: values.otp,
          })
        );
        onClose(true); //
      } else {
        setPasswordNotMatch(true);
      }
      // dispatch(loginUser(values));
    },
  });
  // console.log(email);//
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent bg="blackAlpha.900" color={"#F7FAFC"}>
          <form onSubmit={formik.handleSubmit}>
            <ModalHeader fontWeight={"400"} fontSize="xl" mb="0.5rem">
              Forget password?
            </ModalHeader>
            <ModalCloseButton border={"none"} bg="gray.800" color="#F7FAFC" />
            <ModalBody mt="1rem">
              <Box>
                <Box mt="1rem">
                  <Text fontWeight={"400"} fontSize="lg" mb="0.5rem">
                    Please enter your email, to sent the OTP ?
                  </Text>
                  <Input
                    id="otp"
                    type="text"
                    value={email}
                    // onChange={formik.handleChange}
                    // value={formik.values.otp}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  {sentOTP && (
                    <Box fontSize={"md"} color="green.600" fontWeight={"500"}>
                      If you didn't get the OTP in your email , please click
                      resent.
                    </Box>
                  )}
                </Box>
                <HStack mt="0.5rem">
                  {" "}
                  {/* <Text fontSize={"md"} fontWeight={"400"}>
                  If you didnt get the code, please click{" "}
                </Text> */}
                  <Button
                    border={"none"}
                    // mt="1rem"
                    bg="gray.400"
                    ml="18rem"
                    w="7rem"
                    onClick={() => {
                      setPasswordInput(true);
                      setSentOTP(true);
                      dispatch(postOtpApi({ email }));
                    }}
                  >
                    {sentOTP ? "Resent OTP" : "Sent OTP"}
                  </Button>
                </HStack>
              </Box>

              {passwordInput && (
                <>
                  <InputField
                    label="Enter OTP"
                    type="number"
                    formik={formik}
                    inputId={"otp"}
                    // formikValue={formik.values.password}
                  />
                  <InputField
                    label="Enter New Password"
                    type="password"
                    formik={formik}
                    inputId={"password"}
                  />
                  <InputField
                    label="Confirm Password"
                    type="password"
                    formik={formik}
                    inputId={"newPassword"}
                  />
                  {passwordNotMatch && (
                    <Box color="red.500">
                      New password and confirm password doesn't match!!
                    </Box>
                  )}
                </>
              )}
            </ModalBody>
            <ModalFooter mt="1rem">
              <Button
                color={"#F7FAFC"}
                colorScheme="orange"
                mt="2.5%"
                size="md"
                border={"none"}
                fontSize="1.2rem"
                cursor={"pointer"}
                type="submit"
              >
                Change Password
              </Button>
            </ModalFooter>
            )
          </form>
        </ModalContent>
      </Modal>
    </>
  );
};
