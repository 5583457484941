import React from "react";
import { Box, Button, Center, Flex, HStack, Image } from "@chakra-ui/react";
import { Input, Radio, RadioGroup, Stack } from "@chakra-ui/react";
import { Text, useDisclosure } from "@chakra-ui/react";
import { OtaPopup } from "./OtaPopup";

export const SignupForm = ({ formik, formError }) => {
  const { isOpen, onOpen, onClose } = useDisclosure(); //
  return (
    <>
      <Box>
        <Center color={"#F7FAFC"} mr="20%" mt="1.5%">
          Full Name
        </Center>
        <Center mt="0.7%">
          <Input
            id="full_name"
            type={"text"}
            size="lg"
            width="23.5%"
            bg="#F7FAFC"
            onChange={formik.handleChange}
            value={formik.values.full_name}
          />
        </Center>
        <Box ml="49.5rem">
          {formik.errors.full_name ? (
            <Box style={{ color: "red" }}>{formik.errors.full_name}</Box>
          ) : null}
        </Box>
      </Box>
      <Box>
        <Center color={"#F7FAFC"} mr="19%" mt="1.5%">
          Email Address
        </Center>
        <Center mt="0.7%">
          <Input
            id="email"
            type={"text"}
            size="lg"
            width="23.5%"
            bg="#F7FAFC"
            onChange={formik.handleChange}
            value={formik.values.email}
          />
        </Center>
        <Box ml="49.5rem">
          {" "}
          {formik.errors.email ? (
            <Box style={{ color: "red" }}>{formik.errors.email}</Box>
          ) : null}
        </Box>
      </Box>

      <Box>
        <Center color={"#F7FAFC"} mr="19%" mt="1.5%">
          Phone Number
        </Center>
        <Center mt="0.7%">
          <Input
            id="phone"
            type={"number"}
            size="lg"
            width="23.5%"
            bg="#F7FAFC"
            onChange={formik.handleChange}
            value={formik.values.phone}
          />
        </Center>
        <Box ml="49.5rem">
          {" "}
          {formik.errors.phone ? (
            <Box style={{ color: "red" }}>{formik.errors.phone}</Box>
          ) : null}
        </Box>
      </Box>

      <Box color={"#F7FAFC"}>
        <Center mr="21%" mt="1.5%">
          Gender
        </Center>
        <Center mr="20rem" mt="0.7%">
          <RadioGroup>
            <HStack gap="1rem">
              {" "}
              <Radio
                onChange={() => formik.setFieldValue("gender", "Male")}
                value="1"
              >
                Male
              </Radio>
              <Radio
                value="2"
                onChange={() => formik.setFieldValue("gender", "Female")}
              >
                Female
              </Radio>
            </HStack>
          </RadioGroup>
        </Center>
        <Box ml="49.5rem">
          {formik.errors.gender ? (
            <Box style={{ color: "red" }}>{formik.errors.gender}</Box>
          ) : null}
        </Box>
      </Box>
      <Center color={"#F7FAFC"} mr="20%" mt="1%">
        Password
      </Center>
      <Center mt="0.7%">
        <Input
          id="password"
          type={"password"}
          size="lg"
          width="23.5%"
          bg="#F7FAFC"
          onChange={formik.handleChange}
          value={formik.values.password}
        />
      </Center>
      <Center>
        {formik.errors.password ? (
          <Center style={{ color: "red" }}>{formik.errors.password}</Center>
        ) : null}
      </Center>
      <Center>
        <Button
          color={"#F7FAFC"}
          colorScheme="orange"
          mt="2.5%"
          size="lg"
          border={"none"}
          fontSize="1.2rem"
          cursor={"pointer"}
          type="submit"
          onClick={onOpen}
        >
          SIGN UP
          {formError === false && (
            <OtaPopup
              isOpen={isOpen}
              onClose={onClose}
              email={formik.values.email}
            />
          )}
        </Button>
      </Center>
    </>
  );
};
