import { Box,Text,Center,Image } from "@chakra-ui/react";
import wallet from '../assets/img/icons/wallet.png';

export default function NoData({title}){
    return (
        <Box pt={"40px"} pb={"40px"}>
          <Center mb={4}>
            <Image src={wallet} height={"100px"}/>
          </Center>
          <Text color={"white"} textAlign="center" fontSize={"2xl"}>{title}</Text>
        </Box>
    )
}