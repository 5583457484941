import React from 'react'
import merchImg from '../assets/img/merchImg.png'

function MerchFin() {
    return (
        <>
            <section className="merchFin">
                <div className="merchFin__container">
                    <p className="merchFin-reg" style={{marginBottom: '2.5rem'}}>Thank you for Purchasing with us.</p>
                    <p style={{marginBottom: '3rem'}}>
                        <span className="merchFin-reg">Your tracking </span>
                        <span className="merchFin-bold">Id is 02323</span>
                    </p>
                    <p style={{marginBottom: '9rem'}}>
                        <span className="merchFin-med">Track your order </span>
                        <span className="merchFin-col">Here</span>
                    </p>

                    <div className="merchFin__imgBox">
                        <img src={merchImg} alt="Merch" className="merchFin__img" />
                    </div>
                    <p className="merchFin-reg">MAD BLACK HAT</p>
                    <p className="merchFin-sm">LIMITED EDITION</p>
                    <p style={{marginBottom: '1.5rem'}}>
                        <span className="merchFin-tin">Color : </span>
                        <span className="merchFin-tinBold">Blue</span>
                    </p>
                    <p className="merchFin-med">$100 X 02</p>
                </div>
            </section>   
        </>
    )
}

export default MerchFin
