import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
export const usePersonalDetailsFormValue = () => {
  const validationSchema = Yup.object({
    full_name: Yup.string()
      .min(3, "Too Short!")
      .max(50, "Too Long!")
      .required("Please enter the Full name"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Please enter your email"),
    phone: Yup.number()
      .min(0, "Too Short!")
      .max(100000000000, "Too Long!")
      .required("Please enter your Phone Number"),
  });
  const [formValue, setFormValue] = useState("");
  const formik = useFormik({
    initialValues: {
      full_name: "",
      email: "",
      phone: "",
    },
    validateOnChange: false,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // console.log("values", values);
      setFormValue(values);
    },
  });
  return { formik, formValue, setFormValue };
};
