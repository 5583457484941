import {
  Box,
  Button,
  Center,
  Grid,
  GridItem,
  Image,
  Text,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { TrackOrders } from "../../components/TrackOrders";
import { SidebarAndHeader } from "../../components/WarpperComponent/SidebarAndHeader";
import Moment from "react-moment";

import { useDispatch, useSelector } from "react-redux";
import { allOrderHistory, refreshToken } from "../../redux/ecommerceSlice";
import Loading from "../../components/Loading";
import { NavLink } from "react-router-dom";
import NoData from "../../components/NoData";

export const OrderHistory = () => {
  const dispatch = useDispatch();

  const userOrderData = useSelector((state) => state.ecommerce.allOrderHistory);

  const loading = useSelector((state) => state.ecommerce.loading);

  useEffect(() => {
    dispatch(allOrderHistory());
    dispatch(refreshToken({ refresh: localStorage.getItem("refresh") }));
  }, []);

  return (
    <SidebarAndHeader>
      {/* <TrackOrders /> */}
      {loading ? <Loading /> : ""}
      <Grid
        templateColumns="repeat(4, 1fr)"
        p={8}
        bg="black"
        borderRadius={"20px"}
        color="white"
      >
        <GridItem colSpan={4}>
          <Box fontSize={"2.5rem"} mb={5}>
            Order History
          </Box>
        </GridItem>

        <GridItem colSpan={4}>
          <Grid templateColumns="repeat(4, 1fr)">
            <GridItem colSpan={4}>
              {Array.isArray(userOrderData) ? (
                userOrderData?.map((item) => (
                  <Grid
                    templateColumns="repeat(4, 1fr)"
                    alignItems={"center"}
                    mt={4}
                    mb={4}
                  >
                    <GridItem>
                      <Center
                        bg="gray.900"
                        w="8rem"
                        h="8rem"
                        borderRadius={"1rem"}
                      >
                        <Image
                          borderRadius={"1rem"}
                          src={item.items[0].image}
                          w="6.5rem"
                          h="6.5rem"
                        ></Image>
                      </Center>
                    </GridItem>

                    <GridItem>
                      <Box fontSize={"1.5rem"} mr="20rem">
                        <Text width={"max-content"}>Order Date</Text>

                        <Text width={"max-content"}>
                          {" "}
                          <Moment format="YYYY/MM/DD">{item.order_time}</Moment>
                        </Text>
                      </Box>
                    </GridItem>

                    <GridItem>
                      <Box>
                        <Box width={"max-content"} fontSize={"1.5rem"}>
                          Invoice Number: {item.invoice_number}
                        </Box>
                        <Box color="#F7FAFC" fontSize={"1.5rem"}>
                          Amount: Rs {item.amount}
                        </Box>
                      </Box>
                    </GridItem>

                    <GridItem>
                      <NavLink
                        to={`/user/orderDetail/${item.uuid}/`}
                        style={{ textDecoration: "none" }}
                      >
                        <Button
                          bg="gray.900"
                          color="#F7FAFC"
                          border={"none"}
                          py="1.4rem"
                          px="1.5rem"
                        >
                          View Invoice
                        </Button>
                      </NavLink>
                    </GridItem>
                    <GridItem></GridItem>
                  </Grid>
                ))
              ) : (
                <NoData title={"No Pending Orders"} />
              )}
            </GridItem>
          </Grid>
        </GridItem>
      </Grid>
    </SidebarAndHeader>
  );
};
