import { HamburgerIcon } from "@chakra-ui/icons";
import { Box, Flex, HStack, Image, VStack } from "@chakra-ui/react";
import React from "react";
import { CgProfile } from "react-icons/cg";
import { BsBagCheck, BsShopWindow } from "react-icons/bs";
import { FiPower } from "react-icons/fi";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

export const Sidemenu = ({drawer,setDrawer}) => {
  const profileData = useSelector((state) => state.ecommerce.userProfile); //
  return (
    <Box
      bg="black"
      height={"100vh"}
      width={"100%"}
    >
      <Box position={"relative"} height="100%" p={6}>
        
        <HamburgerIcon color="#F7FAFC" w="3rem" h="2.5rem" onClick={()=>setDrawer(!drawer)} />

        <Box color={"#F7FAFC"} fontSize={"2rem"} fontWeight="bold" mt={4}>
          Dashboard
        </Box>
        <VStack fontSize="1.5rem" gap="4" alignItems={"baseline"} mt={4}>
          <HStack gap="1rem">
            <Box color={"#F7FAFC"}>
              {/* <Image
                src={profileData?.image?.image}
                w="2.5rem"
                borderRadius={"50%"}
              /> */}

              <CgProfile size="25" />
            </Box>
            <NavLink to="/user/profile" style={{ textDecoration: "none" }}>
              <Box color={"#F7FAFC"}>Profile</Box>
            </NavLink>
          </HStack>
          {/* <HStack gap="1rem">
            <Box color={"#F7FAFC"}>
              <MdFavoriteBorder size="27" />
            </Box>
            <Box color={"#F7FAFC"}>Favorites</Box>
          </HStack> */}
          <HStack gap="1rem">
            <Box color={"#F7FAFC"}>
              <BsBagCheck size="22" strokeWidth={"0.5px"} />
            </Box>
            <NavLink to="/user/myOrders" style={{ textDecoration: "none" }}>
              <Box color={"#F7FAFC"}>My Orders</Box>
            </NavLink>
          </HStack>

          <HStack gap="1rem">
            <Box color={"#F7FAFC"}>
              <BsShopWindow size="22" strokeWidth={"0.5px"} />
            </Box>
            <NavLink to="/merch" style={{ textDecoration: "none" }}>
              <Box color={"#F7FAFC"}>Shop</Box>
            </NavLink>
          </HStack>
        </VStack>
        <Flex
          color={"#F7FAFC"}
          fontSize="1.5rem"
          position={"absolute"}
          bottom="5%"
          gap="0.8rem"
        >
          <Box>
            <FiPower size="17" />
          </Box>
          <Box
            color={"#F7FAFC"}
            cursor={"pointer"}
            onClick={() => {
              window.localStorage.clear();
              document.location.reload();
            }}
          >
            Logout
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};
