import {
  Button,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createUser, postOtpApi } from "../../redux/ecommerceSlice";

export const OtaPopup = ({ isOpen, onClose, email }) => {
  const data = useSelector((state) => state.ecommerce);
  // console.log(data.create_User);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      otp: "",
    },
    onSubmit: (values) => {
      const arraValue = { ...data.signUpInfo, otp: values.otp };
      dispatch(createUser(arraValue));
      // console.log("arraValue ", arraValue);
      // console.log(values); //
    },
  });

  useEffect(() => {}, [data.createUser]);

  if (data.create_User === "sucessful") {
    // console.log("nicee");
    navigate("/login");
  }

  return (
    <>
      {/* <Button onClick={onOpen}>Open Modal</Button>{" "} */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <form onSubmit={formik.handleSubmit}>
            <ModalHeader>Email verfication</ModalHeader>
            <ModalCloseButton border={"none"} bg="gray.300" />
            <ModalBody mt="1rem">
              <Text fontWeight={"500"} fontSize="xl" mb="0.5rem">
                Please Enter the code, sent in your email ?
              </Text>
              <Input
                id="otp"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.otp}
              />
              <HStack mt="0.5rem">
                <Text fontSize={"lg"} fontWeight={"400"}>
                  If you didnt get the code, please click{" "}
                </Text>
                <Button
                  border={"none"}
                  mt="1rem"
                  bg="gray.400"
                  onClick={() => dispatch(postOtpApi({ email }))}
                >
                  Resent
                </Button>
              </HStack>
            </ModalBody>
            <ModalFooter mt="1rem">
              <Button
                color={"#F7FAFC"}
                colorScheme="orange"
                mt="2.5%"
                size="lg"
                border={"none"}
                fontSize="1.2rem"
                cursor={"pointer"}
                type="submit"
              >
                REGISTER
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
};
