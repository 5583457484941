import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  addToCart,
  checkOutInfoPersist,
  postCheckOut,
  refreshToken,
  removeSpecificAll_products,
  removeSpecificCart,
  removeSpecificCheckOutInfoPersist,
  totalPriceInludingTax,
  updateAddTocart,
} from "../redux/ecommerceSlice";
import { useDispatch } from "react-redux";
import Nav from "../components/Nav";
import { Box, Grid, GridItem } from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";
import NavSideBar from "../components/NavSideBar";

function Cart() {
  const value = useSelector((state) => state.ecommerce.checkOutInfoPersist);
  const all_products = useSelector((state) => state.ecommerce.all_products);
  const checkoutValue = useSelector((state) => state.ecommerce.all_products);
  const dispatch = useDispatch();
  let history = useNavigate();

  let specificSelection;

  let totalPrice = 0;

  useEffect(() => {
    dispatch(refreshToken({ refresh: localStorage.getItem("refresh") }));
  }, []);
  function totalPriceCalc() {
    value?.map((curr) => {
      let price = curr.price * curr.quantity;
      totalPrice = totalPrice + price;
    });
  }
  totalPriceCalc();

  // used for updating the product quantity in cart
  function changeQuantity(specificSelection, increaseOrDecrease) {
    if (increaseOrDecrease === "+") {
      let updateQuantity = value?.map((curr, indx) => {
        let quantity = curr.quantity;
        if (
          indx === specificSelection &&
          quantity < curr.product_inventory.current
        ) {
          quantity = curr.quantity + 1;
          return { ...curr, quantity };
        }
        return curr;
      });
      dispatch(checkOutInfoPersist(updateQuantity));
    }
    if (increaseOrDecrease === "-") {
      let updateQuantity = value?.map((curr, indx) => {
        if (indx === specificSelection) {
          let quantity = curr.quantity === 1 ? 1 : curr.quantity - 1;
          return { ...curr, quantity };
        }
        return curr;
      });
      dispatch(checkOutInfoPersist(updateQuantity));
    }
  }
  function changeQuantityOfAll_products(specificSelection, increaseOrDecrease) {
    if (increaseOrDecrease === "+") {
      let updateQuantity = value?.map((curr, indx) => {
        let quantity = curr.quantity;
        if (
          indx === specificSelection &&
          quantity < curr.product_inventory.current
        ) {
          quantity = curr.quantity + 1;
          return { ...curr, quantity };
        }
        return curr;
      });
      dispatch(updateAddTocart(updateQuantity));
    }
    if (increaseOrDecrease === "-") {
      let updateQuantity = value?.map((curr, indx) => {
        if (indx === specificSelection) {
          let quantity = curr.quantity === 1 ? 1 : curr.quantity - 1;
          return { ...curr, quantity };
        }
        return curr;
      });
      dispatch(updateAddTocart(updateQuantity));
    }
  }
  dispatch(totalPriceInludingTax({ totalPrice }));
  function deleteCheckOutCart(id) {
    dispatch(removeSpecificCart(id));
    dispatch(removeSpecificAll_products(id));
    dispatch(removeSpecificCheckOutInfoPersist(id));
  }

  useEffect(() => {
    if (totalPrice < 1) {
      history("/merch");
    }
  }, [totalPrice]);

  return (
    <Box bg={"black"} height="100%">
      <NavSideBar />
      <section
        className="cart"
        style={{ overflowX: "hidden", overflowY: "auto" }}
      >
        {/* {console.log("value id", value?.id)} */}
        <Grid templateColumns="repeat(2, 1fr)" gap={4}>
          <GridItem colSpan={{ base: 1, md: 1, sm: 2 }}>
            <div className="cart__det">
              <p className="cart-title">PRODUCT YOU PICKED</p>
              <div className="cart__products">
                <Grid templateColumns="repeat(3, 1fr)" gap={4}>
                  {value?.map((curr, index) => {
                    return (
                      <GridItem
                        colSpan={{ base: 1, md: 1, sm: 2 }}
                        position="relative"
                      >
                        <DeleteIcon
                          color="white"
                          w="1.7rem"
                          h="1.7rem"
                          position="absolute"
                          right="1rem"
                          top="1rem"
                          cursor="pointer"
                          onClick={() => deleteCheckOutCart(curr.id)}
                        />
                        <div className="cart__prod" key={index}>
                          <img
                            src={curr.gallary}
                            alt=""
                            className="cart__prod-img"
                          />
                          <p className="cart-btn">
                            <span
                              onClick={() => {
                                specificSelection = index;
                                // console.log(specificSelection);

                                changeQuantity(specificSelection, "-");
                                changeQuantityOfAll_products(
                                  specificSelection,
                                  "-"
                                );
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              -
                            </span>
                            <span> {curr.quantity}</span>

                            <span
                              onClick={() => {
                                specificSelection = index;
                                // console.log(specificSelection);

                                changeQuantity(specificSelection, "+");
                                changeQuantityOfAll_products(
                                  specificSelection,
                                  "+"
                                );
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              {" "}
                              +
                            </span>
                          </p>
                        </div>
                      </GridItem>
                    );
                  })}
                </Grid>
              </div>
            </div>
          </GridItem>

          <GridItem colSpan={{ base: 1, md: 1, sm: 2 }}>
            <div className="cart__total">
              <p className="cart-uName">Total Order</p>

              <div className="cart__total-details">
                {value?.map((curr, index) => {
                  return (
                    <div className="cart__total-item" key={index}>
                      <div className="cart__total-item--imgBox">
                        <img
                          src={curr.gallary}
                          alt=""
                          className="cart__total-item--img"
                        />
                      </div>
                      <div className="cart__total-item--desc">
                        <p className="cart-prodName">{curr.name}</p>
                        <p className="cart-prodCate">LIMITED EDITION</p>
                        <p>
                          <span className="cart-prodCateReg">Color: </span>
                          <span className="cart-prodCate">{curr.color} </span>
                        </p>
                        <p className="cart-prodCateReg">
                          <span>Size:</span>
                          <span className="cart-prodCate">{curr.size}</span>
                        </p>
                        <p className="cart-price">
                          {curr.price} X {curr.quantity}
                        </p>
                      </div>
                    </div>
                  );
                })}
                {/* <div className="cart__total-ship">
                  <p className="cart-ship">Standard Shipping</p>
                  <p className="cart-shipPrice">Rs 100</p>
                </div> */}
                <div
                  className="cart__total-amount"
                  style={{ marginTop: "4rem" }}
                >
                  <p className="cart-total">TOTAL BILL</p>
                  <p className="cart-total">Rs {totalPrice}</p>
                </div>
                <div className="cart__total-btnWrapper">
                  <Link to="/merch-pay-detail">
                    <button
                      className="btn-trans"
                      // onClick={() => dispatch(postCheckOut(arrValueForApi))}
                    >
                      Check out
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </GridItem>
        </Grid>
      </section>
    </Box>
  );
}

export default Cart;
