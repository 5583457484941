import React from "react";
import { HamburgerIcon } from "@chakra-ui/icons";
import Account from "../assets/img/icons/account.png";
import { Text, Grid, GridItem, Box, Image } from "@chakra-ui/react";
import Logo from "../assets/img/icons/logo.png";
import { NavLink } from "react-router-dom";

export const Header = ({ profileData, drawer, setDrawer }) => {
  return (
    <>
      <Grid
        templateColumns="repeat(12, 1fr)"
        borderBottom={"1px solid white"}
        p={3}
      >
        <GridItem colSpan={10}>
          <HamburgerIcon
            display={drawer ? "block" : "none"}
            color="#F7FAFC"
            w="3rem"
            h="2.5rem"
            onClick={() => setDrawer(!drawer)}
            position={"absolute"}
          />
          <NavLink to="/">
            <Image src={Logo} height="4rem" ml={12} />
          </NavLink>
        </GridItem>
        <GridItem>
          <Box display={"flex"} alignItems="center">
            {/* <IoIosNotificationsOutline size={29} color="white" /> */}
            <img
              src={profileData?.profile ? profileData?.profile : Account}
              style={{
                width: "4rem",
                height: "4rem",
                borderRadius: "100%",
                marginLeft: "10px",
                marginRight: "10px",
              }}
              alt="profile img"
            />
            <Text
              color="#F7FAFC"
              fontWeight={"400"}
              fontSize={"1.5rem"}
              width="max-content"
            >
              {profileData?.full_name}
            </Text>
          </Box>
        </GridItem>
      </Grid>
    </>
  );
};
