import React, { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Box, Grid, Center, Checkbox, Flex, Text, useDisclosure } from "@chakra-ui/react";
import { GridItem, RadioGroup, Stack, Radio, HStack } from "@chakra-ui/react";
import { Image, Select, Input, VStack, Button } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import {
	addToCart,
	automaticUserCreate,
	checkOutInfoPersist,
	clearCart,
	clearCheckout,
	postCheckOut,
	postCheckOutCashOnDelivery,
	resetautomaticUserCreateState,
} from "../../redux/ecommerceSlice";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { userProfile as fetchProfile } from "../../redux/ecommerceSlice";
import KhaltiCheckout from "khalti-checkout-web";
import Nav from "../../components/Nav";
import Loading from "../../components/Loading";
import { getShippingAddress } from "../../redux/ecommerceSlice";
import { createStandaloneToast } from "@chakra-ui/react";
import { totalPriceInludingTax as dispatchAmount } from "../../redux/ecommerceSlice";
import NavSideBar from "../../components/NavSideBar";
import { countryData, countryList } from "../../assets/countryData";
import { cities } from "../../assets/cityData";
import { OrderDetails } from "../MyOrders.js/OrderDetails/OrderDetails";
import { OrderDetailsOfPay } from "./OrderDetailsOfPay";
import { PersonalDetails } from "./PersonalDetails";
import { useFormik } from "formik";
import { usePersonalDetailsFormValue } from "./usePersonalDetailsFormValue";
import PurchaseDetails from "./PurchaseDetails";
import PaymentSelection from "./PaymentSelection";
import PurchaseCompleted from "./PurchaseCompleted";

function Pay() {
	let history = useNavigate();
	const userProfile = useSelector((state) => state.ecommerce.userProfile);
	const all_products = useSelector((state) => state.ecommerce.all_products);
	// const getShippingAddress = useSelector(
	//   (state) => state.ecommerce.getShippingAddress
	// );
	const totalPriceInludingTax = useSelector(
		(state) => state.ecommerce.totalPriceInludingTax
	);
	const [page, setPage] = useState('shippingDetail')

	const dispatch = useDispatch();

	const [lat, setLat] = useState(27.7172);
	const [lng, setLng] = useState(85.324);
	const [addr, setAddr] = useState("");
	const [shippingAdr, setShippingAdr] = useState(false);
	// logic for only one selection of shipping details
	const [checkedOfPick, setcheckedOfPick] = useState(false);
	const [checkedMyLocation, setcheckedMyLocation] = useState(false);
	const [defaultLocation, setdefaultLocation] = useState(true);
	const [val, setVal] = React.useState(0);
	const [isvalley, setIsvalley] = useState(true);

	const [country, setCountry] = useState("Nepal");
	const [state, setState] = useState("");
	const [city, setCity] = useState("");
	const [area, setArea] = useState("");
	const [address, setAddress] = useState("");
	const [selfpickup, setSelfpickup] = useState(false);

	const { formik, formValue, setFormValue } = usePersonalDetailsFormValue();
	const [makePaymentState, setMakePaymentState] = useState("");

	const locationData = {
		myLocation: userProfile?.location,
		self: "Self Pick Up?",
	};

	const loading = useSelector((state) => state.ecommerce.loading);
	const automaticUserCreateState = useSelector(
		(state) => state.ecommerce.automaticUserCreate
	);
	const checkOutStatus = useSelector((state) => state.ecommerce.checkOutStatus);
	const checkOutInfoPersist = useSelector(
		(state) => state.ecommerce.checkOutInfoPersist
	);
	const shippingAddress = useSelector(
		(state) => state.ecommerce.getShippingAddress
	);
	const postCheckOutData = useSelector((state) => state.ecommerce.postCheckOut);
	const ecommerce = useSelector((state) => state.ecommerce);
	const toast = createStandaloneToast();

	useEffect(() => {
		if (checkOutStatus === "success") {
			toast({
				title: "Success",
				status: "success",
				duration: 2000,
				isClosable: true,
				position: "top",
			});

			// setPage('purchaseCompleted');
			// setTimeout(

			// 	1000
			// )
			dispatch(clearCart())
			{
				loading ? console.log("waiting") : history("/user/myOrders/");
			}
		}
		if (checkOutStatus === "rejected") {
			if (postCheckOutData?.status >= 500) {
				toast({
					title: "something went wrong",
					status: "warning",
					duration: 2000,
					isClosable: true,
					position: "top",
				});
			}
			if (postCheckOutData?.status >= 400) {
				toast({
					title: postCheckOutData?.data?.message,
					status: "warning",
					duration: 2000,
					isClosable: true,
					position: "top",
				});
			}
		}
	}, [checkOutStatus]);
	function shippingCharge() {
		if (selfpickup === false) {
			// if (city === "Kathmandu" || city === "Bhaktapur" || city === "Lalitpur") {
			// 	return 80;
			// } else {
			// 	return 150;
			// }
			return 150;
		} else {
			return 0;
		}
	}

	let config = {
		// replace this key with yours
		publicKey: "live_public_key_71b217bd2f5c48449fd95ada7bb1941c",
		// publicKey: "test_public_key_3ccd85ac24764f9081d6b8c546724d58",
		productIdentity: "1234567890" + new Date().getTime(),
		productName: "mingma",
		productUrl: "https://mingmadavidsherpa.com/",
		eventHandler: {
			onSuccess(payload) {
				// hit merchant api for initiating verfication
				dispatch(
					postCheckOut({
						token: payload.token,
						amount: parseInt(payload.amount),
						total_bill: parseFloat(
							payload.amount + 0.13 * payload.amount
						).toFixed(2),
						tax: 0,
						shipping_charge: shippingCharge(),
						latitude: lat,
						longitude: lng,
						location: addr
							? addr
							: `${country} ${state} ${city} ${area} ${address}`,
						self_pick: selfpickup,
						all_products,
					})
				);
			},
			// onError handler is optional
			onError(error) {
				// handle errors
			},
			onClose() {
				// console.log("widget is closing");
			},
		},
		paymentPreference: ["KHALTI"],
	};

	const [checkout, setcheckout] = useState(new KhaltiCheckout(config));

	useEffect(() => {
		setcheckout(new KhaltiCheckout(config));
	}, [selfpickup]);

	useEffect(() => {
		dispatch(fetchProfile());
		dispatch(getShippingAddress());
	}, []);

	// logic for only one selection of shipping details
	useEffect(() => {
		setShippingAdr(addr);
		setcheckedMyLocation(false);
		setcheckedOfPick(false);
		setdefaultLocation(false);
	}, [addr]);

	useEffect(() => {
		if (!isvalley) {
			toast({
				title: "Outside of valley! May charge extra shipping charge.",
				status: "warning",
				duration: 2000,
				isClosable: true,
				position: "top",
			});
		}
	}, [isvalley]);

	function makePayment(type) {
		if (userProfile?.phone === "" || userProfile?.phone === "undefined") {
			toast({
				title: "No Phone Number.",
				description: "Please go to your profile and add your phone number.",
				status: "warning",
				duration: 3000,
				isClosable: true,
				position: "top",
				containerStyle: {
					width: "400px",
				},
			});
		} else {
			if (selfpickup) {
				if (addr === "") {
					toast({
						title: "Please select shipping address4",
						status: "warning",
						duration: 3000,
						isClosable: true,
						position: "top",
					});
				} else {
					if (type === "khalti") {
						checkout.show({
							amount:
								Math.round(Math.round(totalPriceInludingTax?.totalPrice)) * 100,
						});
					}
					if (type === "cash") {
						dispatch(
							postCheckOutCashOnDelivery({
								tax: 0,
								shipping_charge: shippingCharge(),
								latitude: lat,
								longitude: lng,
								location: addr,
								self_pick: selfpickup,
								all_products,
							})
						);
					}
				}
			} else {
				if (state === "" || address === "") {
					toast({
						title: "Please enter shipping address",
						status: "warning",
						duration: 3000,
						isClosable: true,
						position: "top",
					});
				} else {
					if (type === "khalti") {
						checkout.show({
							amount:
								Math.round(Math.round(totalPriceInludingTax?.totalPrice) + shippingCharge()) * 100,
						});
					}
					if (type === "cash") {
						// console.log("i am from cashjbjbj niceeee", formValue);
						dispatch(
							postCheckOutCashOnDelivery({
								tax: 0,
								shipping_charge: shippingCharge(),
								latitude: lat,
								longitude: lng,
								location: `${country} ${state} ${city} ${area} ${address}`,
								self_pick: selfpickup,
								all_products,
							})
						);
					}
				}
			}
		}
	}

	// below  useeffect used for squencial call of automaticUserCreate api and postCheckOutCashOnDelivery api
	useEffect(() => {
		if (formValue) {
			dispatch(automaticUserCreate(formValue));
		}
		// console.log("formvalue", formValue);
		// dispatch(rese)
	}, [formValue]);

	useEffect(() => {
		makePayment(makePaymentState);
		// console.log("zz", ecommerce.automaticUserCreatePayload?.status);
		if (ecommerce.automaticUserCreateState) {
			history("/user/myOrders/");
			setFormValue("");
			resetautomaticUserCreateState();
		}
		if (+ecommerce.automaticUserCreatePayload?.status === 400) {
			toast({
				title: "Email in our account already exists.Please Login.",
				status: "warning",
				duration: 2000,
				isClosable: true,
				position: "top",
			});
		}
	}, [automaticUserCreateState]);

	// useEffect(() => {
	//   if (ecommerce.authenticateAutomaticUserCreate) {
	//     history("/user/myOrders/");
	//   }
	// }, [ecommerce.authenticateAutomaticUserCreate]);
	// console.log("formvalues", formValue);

	function makePaymentConditon(type) {
		if (ecommerce.authenticate) {
			makePayment(type);
			// console.log('zzz')
		} else {
			// console.log('xxx')
			setMakePaymentState(type);
		}
		formik.handleSubmit();
	}

	function cashOnDeliveryOnClickHandeler() {
		if (selfpickup) {
			makePaymentConditon("cash");
		} else if (state === "" || address === "") {
			toast({
				title: "Please select shipping address3",
				status: "warning",
				duration: 3000,
				isClosable: true,
				position: "top",
			});
		} else {
			makePaymentConditon("cash");
		}
	}
	function khaltiPaymentOnClickHandeler() {
		if (selfpickup) {
			console.log("z selfpickup", selfpickup);
			makePaymentConditon("khalti");
		} else if (state === "" || address === "") {
			toast({
				title: "Please select shipping address2",
				status: "warning",
				duration: 3000,
				isClosable: true,
				position: "top",
			});
		} else {
			makePaymentConditon("khalti");
		}
	}

	function handleShippingConfirmation() {
		if ((state && address) || (selfpickup && addr
			// && userProfile?.full_name && userProfile?.phone && userProfile?.email

		)) {
			// history('/merch-purchase-detail')
			setPage('purchaseDetail');
			return
		}
		toast({
			title: "Please select shipping address1",
			status: "warning",
			duration: 3000,
			isClosable: true,
			position: "top",
		});
	}

	return (
		<Box bg={"black"} h='100vh'>
			<NavSideBar />
			{loading ? <Loading /> : ""}
			<section className="pay">

				{/*----------------------------------------------------------------personal details &  Shipping and pick up------------------------------------------------------------------------------------------------- */}
				{
					page === 'shippingDetail' &&
					<Box
						color='white'
						pt='80px'
						px='1rem'
						fontSize='2rem'
						height={'100vh'}
					>
						<Flex
							h='80rem'
							pos='relative'
							direction='column'
							p='1rem'
							maxW='70rem'
							mx='auto'
							mb='5rem'
							gap='3rem'
						>
							<Text
								textAlign='right'
								color='#A0A0A0'
								_hover={{ cursor: 'pointer' }}
								w='100%'
							>
								{userProfile ? '' :
									<NavLink to={'/login'}>
										<Text color={'lightgray'} >Login?</Text>
									</NavLink>
								}
							</Text>
							<Flex w='100%'>
								<Box w='50%'
									fontSize='2rem'
									p='1.5rem'
									bg={!selfpickup ? '#EAA13F' : '#343434'}
									textAlign='center'
									borderLeftRadius='25px'
									_hover={{ cursor: 'pointer' }}
									onClick={() => setSelfpickup(false)}
								>
									Shipping
								</Box>
								<Box w='50%'
									fontSize='2rem'
									p='1.5rem'
									bg={selfpickup ? '#EAA13F' : '#343434'}
									textAlign='center'
									borderRightRadius='25px'
									_hover={{ cursor: 'pointer' }}
									onClick={() => setSelfpickup(true)}
								>
									Pick Up
								</Box>
							</Flex>
							{!selfpickup ? (
								<Flex direction='column' gap='2rem' >
									<Select
										fontSize="3xl"
										h="5.4rem"
										borderColor='#343434'
										style={{ color: '#A0AEC0' }}
										borderRadius="1.4rem"
										defaultValue={'Nepal'}
										onChange={(e) => setCountry(e.target.value)}
									>
										<option value={'Nepal'}>&nbsp;&nbsp;&nbsp;&nbsp;Nepal</option>
									</Select>
									<Select
										placeholder="&nbsp;&nbsp;&nbsp;&nbsp;Select your State"
										_placeholder={{ color: 'red' }}
										fontSize="3xl"
										h="5.4rem"
										borderColor='#343434'
										style={{ color: '#A0AEC0' }}
										borderRadius="1.4rem"
										onChange={(e) => setState(e.target.value)}
									>
										<option>&nbsp;&nbsp;&nbsp;&nbsp;Province No. 1</option>
										<option>&nbsp;&nbsp;&nbsp;&nbsp;Province No. 2</option>;
										<option>&nbsp;&nbsp;&nbsp;&nbsp;Province No. 3</option>;
										<option>&nbsp;&nbsp;&nbsp;&nbsp;Province No. 5</option>;
										<option>&nbsp;&nbsp;&nbsp;&nbsp;Gandaki</option>;
										<option>&nbsp;&nbsp;&nbsp;&nbsp;Karnali</option>;
										<option>&nbsp;&nbsp;&nbsp;&nbsp;SudurPaschim</option>;
									</Select>
									<Input
										type="text"
										borderColor="#343434"
										py='2.5rem'
										px='3rem'
										fontSize="3xl"
										placeholder="Full Address"
										borderRadius="1.4rem"
										color="white"
										value={address}
										onChange={(e) => setAddress(e.target.value)}
									/>
								</Flex>

							) : (
								<Grid
									templateColumns='repeat(2, 1fr)'
									justifyContent='flex-end'
									my='3rem'
									gap='2rem'
								>
									{shippingAddress?.data.map((address) => {
										return (
											<GridItem colSpan='1fr' align='center'>
												<Flex
													justify='center'
													p='1rem'
													align='center'
													h='11rem'
													maxW='25rem'
													borderRadius='1.4rem'
													textAlign='center'
													cursor="pointer"
													overflow='hidden'
													bg={addr === address?.value ? "#f77f3c" : "#343434"}
													onClick={() => setAddr(address.value)}
												>
													<Text>{address.value}</Text>
												</Flex>
											</GridItem>
										);
									})}
								</Grid>
							)
							}

							<Flex direction='column' gap='2rem' >
								<Text>Personal Details</Text>
								<form>
									<Input
										id="full_name"
										type="text"
										borderColor='#343434'
										py='2.5rem'
										px='3rem'
										fontSize="3xl"
										placeholder="Full Name"
										borderRadius="1.4rem"
										color="white"
										defaultValue={userProfile?.full_name ? userProfile.full_name : ''}
										onChange={formik.handleChange}
									/>
									<Input
										id="phone"
										type="text"
										borderColor='#343434'
										py='2.5rem'
										px='3rem'
										fontSize="3xl"
										placeholder="Contact Number"
										borderRadius="1.4rem"
										color="white"
										defaultValue={userProfile?.phone ? userProfile.phone : ''}
										onChange={formik.handleChange}
										mt={'2rem'}
									/>
									<Input
										id="email"
										type="text"
										borderColor="#343434"
										py='2.5rem'
										px='3rem'
										fontSize="3xl"
										placeholder="Email Address"
										borderRadius="1.4rem"
										color="white"
										onChange={formik.handleChange}
										mt={'2rem'}
										defaultValue={userProfile?.email ? userProfile.email : ''}
									/>
								</form>
							</Flex>
							<Button
								w='40rem'
								bg='#EAA13F'
								fontSize='4xl'
								fontWeight='none'
								color='white'
								py='3rem'
								border='none'
								borderRadius='1.4rem'
								_hover={{ cursor: 'pointer' }}
								onClick={handleShippingConfirmation}
								pos="absolute"
								bottom={0}
								left='0'
								right='0'
								margin='auto'
							>
								CONTINUE
							</Button>
						</Flex>
					</Box>
				}

				{/*---------------------------------------------------------end of personal details &  Shipping and pick up ------------------------------------------------------------------------------------------------- */}


				{/*----------------------------------------------------------------Final Confirmation and bill ------------------------------------------------------------------------------------------------- */}
				{
					page === 'purchaseDetail' &&
					<PurchaseDetails shippingAmount={shippingCharge()} deliveryLocation={selfpickup ? addr : address + ', ' + state + ', ' + country} setPage={setPage} />
				}
				{/*----------------------------------------------------------------end of Final Confirmation and bill ------------------------------------------------------------------------------------------------- */}

				{/*----------------------------------------------------------------Make Purchase payment ------------------------------------------------------------------------------------------------- */}
				{page === 'selectPayment' &&
					<PaymentSelection khaltiPaymentOnClickHandeler={khaltiPaymentOnClickHandeler} cashOnDeliveryOnClickHandeler={cashOnDeliveryOnClickHandeler} />
				}
				{/*----------------------------------------------------------------End ofMake Purchase payment ------------------------------------------------------------------------------------------------- */}

				{
					page === "purchaseCompleted" &&
					<PurchaseCompleted checkOutInfoPersist={checkOutInfoPersist} totalPriceInludingTax={totalPriceInludingTax} deliveryLocation={selfpickup ? addr : address + ', ' + state + ', ' + country} shippingAmount={shippingCharge()} />
				}
			</section>
		</Box>
	);
}

export default Pay;
