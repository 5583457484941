import { Box, Center, Grid, GridItem, HStack } from "@chakra-ui/react";
import { Image, VStack, Text, Spinner } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import { TrackOrders } from "../../components/TrackOrders";
import { useDispatch, useSelector } from "react-redux";
import Moment from "react-moment";

import { refreshToken, userOrderDetails } from "../../redux/ecommerceSlice";
import { SidebarAndHeader } from "../../components/WarpperComponent/SidebarAndHeader";
import { NavLink } from "react-router-dom";
import wallet from "../../assets/img/icons/wallet.png";
import NoData from "../../components/NoData";
import Loading from "../../components/Loading";

export const MyOrders = () => {
  const dispatch = useDispatch();
  const userOrderData = useSelector(
    (state) => state.ecommerce.userOrderDetails
  );

  const loading = useSelector((state) => state.ecommerce.loading);
  const checkOutStatus = useSelector((state) => state.ecommerce.checkOutStatus);

  // console.log("my order compoinent ran");

  useEffect(() => {
    dispatch(userOrderDetails());
    dispatch(refreshToken({ refresh: localStorage.getItem("refresh") }));
    // this logic is used for real time update in myorders
    if (checkOutStatus === "done") {
      dispatch(userOrderDetails());
    }
  }, []);

  // /this logic is used for real time update in myorders
  if (checkOutStatus === "sucess") {
    dispatch(userOrderDetails());
  }

  return (
    <SidebarAndHeader>
      {/* <TrackOrders /> */}
      {loading ? <Loading /> : ""}
      {checkOutStatus === "loading" ? <Loading /> : ""}
      <Grid templateColumns="repeat(4, 1fr)" columnGap={"9rem"}>
        <GridItem>
          <HStack gap="9rem">
            <Box>
              <Center color="#F7FAFC" fontSize={"1.3rem"}>
                PENDING ORDERS
              </Center>
              <Center color="#F7FAFC" fontSize={"4rem"}>
                {userOrderData?.pending_order
                  ? userOrderData?.pending_order
                  : 0}
              </Center>
            </Box>
            <VStack w="0.2rem" h="8rem" bg="white"></VStack>
          </HStack>
        </GridItem>

        <GridItem>
          <HStack gap="9rem">
            <Box>
              <Center color="#F7FAFC" fontSize={"1.3rem"}>
                COMPLETED ORDERS
              </Center>
              <Center color="#F7FAFC" fontSize={"4rem"}>
                {userOrderData?.completed_order
                  ? userOrderData?.completed_order
                  : 0}
              </Center>
            </Box>
            <VStack w="0.2rem" h="8rem" bg="white"></VStack>
          </HStack>
        </GridItem>

        <GridItem>
          <HStack gap="9rem">
            <Box>
              <Center color="#F7FAFC" fontSize={"1.3rem"}>
                SHIPPED ORDERS
              </Center>
              <Center color="#F7FAFC" fontSize={"4rem"}>
                {userOrderData?.shipped_order
                  ? userOrderData?.shipped_order
                  : 0}
              </Center>
            </Box>
          </HStack>
        </GridItem>
      </Grid>

      <Grid templateColumns="repeat(5, 1fr)" mt={10} gap={4}>
        <GridItem
          colSpan={{ base: 2, md: 2, sm: 5 }}
          p={6}
          bg="black"
          borderRadius={"2rem"}
          mb={{ base: 0, md: 0, sm: 4 }}
        >
          <Grid templateColumns="repeat(2, 1fr)">
            <GridItem colSpan="1">
              <Text color="#F7FAFC" fontSize={"2.5rem"}>
                Pending Orders
              </Text>
            </GridItem>

            <GridItem colSpan="1" alignSelf={"center"}>
              <NavLink
                to="/user/pendingOrders/"
                style={{ textDecoration: "none" }}
              >
                <Text color="#F7FAFC" fontSize={"1.5rem"} textAlign="right">
                  View All
                </Text>
              </NavLink>
            </GridItem>
          </Grid>

          {userOrderData?.pending_order_data?.length < 1 ||
          userOrderData?.message === "No order available" ||
          userOrderData?.status >= 400 ? (
            <NoData title={"No Pending Orders"} />
          ) : (
            userOrderData?.pending_order_data?.map(
              (item, i) =>
                i < 4 && (
                  <NavLink
                    to={`/user/orderDetail/${item.uuid}/`}
                    style={{ textDecoration: "none" }}
                  >
                    <Box>
                      <HStack gap="2rem">
                        <Center
                          w="7rem"
                          h="7rem"
                          bg="gray.900"
                          borderRadius={"1rem"}
                        >
                          <Image
                            src={item.items[0][0]?.image}
                            w="100%"
                            h="100%"
                            padding="0.5rem"
                          />
                        </Center>
                        <Box>
                          {" "}
                          <Box color="#F7FAFC" fontSize={"1.5rem"}>
                            Invoice Number: {item.invoice_number}
                          </Box>
                          <Box color="#F7FAFC" fontSize={"1.5rem"}>
                            Order Date:&nbsp;
                            <Moment format="YYYY/MM/DD">
                              {item.order_time}
                            </Moment>
                            {/* Order Time: {item.order_timex``} */}
                          </Box>
                          <Box color="#F7FAFC" fontSize={"1.3rem"}>
                            Amount: Rs {item.amount}
                          </Box>
                        </Box>
                      </HStack>
                    </Box>
                    <Box
                      bg="gray.900"
                      w="inherit"
                      h="0.2rem"
                      mt="2rem"
                      mb="2rem"
                    ></Box>
                  </NavLink>
                )
            )
          )}
        </GridItem>

        <GridItem
          colSpan={{ base: 2, md: 2, sm: 5 }}
          p={6}
          bg="black"
          borderRadius={"2rem"}
        >
          <Grid templateColumns="repeat(2, 1fr)">
            <GridItem colSpan="1">
              <Text color="#F7FAFC" fontSize={"2.5rem"}>
                Orders History
              </Text>
            </GridItem>

            <GridItem colSpan="1" alignSelf={"center"}>
              <NavLink
                to="/user/orderHistory/"
                style={{ textDecoration: "none" }}
              >
                <Text color="#F7FAFC" fontSize={"1.5rem"} textAlign="right">
                  View All
                </Text>
              </NavLink>
            </GridItem>
          </Grid>

          {userOrderData?.order_history?.length < 1 ||
          userOrderData?.message === "NO order" ||
          userOrderData?.status >= 400 ? (
            <NoData title={"No Order History"} />
          ) : (
            userOrderData?.order_history?.map(
              (item, i) =>
                i < 4 && (
                  <NavLink
                    to={`/user/orderDetail/${item.order}/`}
                    style={{ textDecoration: "none" }}
                  >
                    <Box>
                      <HStack gap="2rem">
                        <Center
                          w="7rem"
                          h="7rem"
                          bg="gray.900"
                          borderRadius={"1rem"}
                        >
                          <Image
                            src={item.items[0][0].image}
                            w="5rem"
                            h="5rem"
                          />
                        </Center>
                        <Box>
                          {" "}
                          <Box color="#F7FAFC" fontSize={"1.5rem"}>
                            Invoice Number: {item.invoice_number}
                          </Box>
                          <Box color="#F7FAFC" fontSize={"1.5rem"}>
                            Order Time:&nbsp;
                            <Moment format="YYYY/MM/DD">
                              {item.order_time}
                            </Moment>
                          </Box>
                          <Box color="#F7FAFC" fontSize={"1.3rem"}>
                            Amount: {item.amount}
                          </Box>
                        </Box>
                      </HStack>
                    </Box>
                    <Box
                      bg="gray.900"
                      w="inherit"
                      h="0.2rem"
                      mt="1rem"
                      mb="1rem"
                    ></Box>
                  </NavLink>
                )
            )
          )}
        </GridItem>
      </Grid>
    </SidebarAndHeader>
  );
};
