import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  HStack,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { useState } from "react";

export const DetailsInfo = ({ orderDetailData }) => {
  let total = 0.0;
  {
    orderDetailData?.all_product.map(
      (item) =>
        (total = total + parseFloat(item.price) * parseFloat(item.quantity))
    );
  }

  return (
    <Flex
      flexDirection={"column"}
      gap="2.3rem"
      bg="black"
      p="1.8rem"
      borderRadius={"2rem"}
      lineHeight="1"
    >
      <Box>
        <Grid templateColumns="repeat(2, 1fr)" mb={6}>
          <GridItem>
            <Text fontSize={"2xl"} mb={2}>
              Order Date
            </Text>
            <Text fontSize={"xl"}>
              {orderDetailData
                ? new Date(orderDetailData.order_date)
                    .toISOString()
                    .split("T")[0]
                : ""}
            </Text>
          </GridItem>
          <GridItem textAlign={"right"}>
            <Text fontSize={"2xl"} mb={2}>
              Order Status
            </Text>
            <Text fontSize={"xl"}>{orderDetailData?.order_status}</Text>
          </GridItem>
        </Grid>

        <Grid templateColumns="repeat(2, 1fr)">
          <GridItem>
            <Text fontSize={"2xl"} mb={2}>
              Shipping Address
            </Text>
            <Text fontSize={"xl"}>{orderDetailData?.address}</Text>
          </GridItem>
          <GridItem textAlign={"right"}>
            <Text fontSize={"2xl"} mb={2}>
              Shipping Method
            </Text>
            <Text fontSize={"xl"}>{orderDetailData?.shipping_charge > 0 ?'Delivery':'Self Pick Up'}</Text>
          </GridItem>
        </Grid>

        <Box bg="gray.900" w="60rem" h="0.2rem" mt="1rem"></Box>

        <Text fontSize={"2xl"} mt={5} mb={5}>
          Order Items
        </Text>

        {orderDetailData?.all_product.map((item) => (
          <Grid templateColumns="repeat(3, 1fr)" mb={4}>
            {/* {()=>setTotal(total + (item.price * item.quantity))} */}
            <GridItem>
              <Text fontSize={"xl"} mb={2}>
                {item.product}
              </Text>
              <Text fontSize={"lg"}>
                Size: {item.size} / Color: {item.color}{" "}
              </Text>
            </GridItem>
            <GridItem textAlign={"center"}>
              <Text fontSize={"2xl"} mb={2}>
                x{item.quantity}
              </Text>
            </GridItem>
            <GridItem textAlign={"right"}>
              <Text fontSize={"2xl"} mb={2}>
                Rs {item.price * item.quantity}
              </Text>
            </GridItem>
          </Grid>
        ))}

          <Grid templateColumns="repeat(3, 1fr)" mb={4}>
            {/* {()=>setTotal(total + (item.price * item.quantity))} */}
            <GridItem colSpan={2}>
              <Text fontSize={"xl"} mb={2}>
                Shipping Charge
              </Text>
            </GridItem>

            <GridItem textAlign={"right"}>
              <Text fontSize={"2xl"} mb={2}>
                Rs {orderDetailData?.shipping_charge}
              </Text>
            </GridItem>
          </Grid>

        <Box bg="gray.900" w="60rem" h="0.2rem" mt="1rem"></Box>

        <Grid templateColumns="repeat(2, 1fr)" mt={6}>
          <GridItem>
            <Text fontSize={"3xl"} mb={2}>
              Total
            </Text>
            <Text fontSize={"xl"}>Payment Method</Text>
          </GridItem>
          <GridItem textAlign={"right"}>
            <Text fontSize={"3xl"} mb={2}>
              Rs {total + orderDetailData?.shipping_charge}
            </Text>
            <Text fontSize={"xl"}>{orderDetailData?.payment_method}</Text>
          </GridItem>
        </Grid>
      </Box>
    </Flex>
  );
};
