import { Box, Input, Flex, Button, useDisclosure } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { userProfile, updateEmail } from "../../../redux/ecommerceSlice";
import { PasswordConfirmModel } from "./PasswordConfirmModel";
import { EmailOTP } from "./EmailOtp";

export const EmailChange = ({ profileData }) => {
  const dispatch = useDispatch();

  const ecommerce = useSelector((state) => state.ecommerce);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const updateUseEmail = (email) => {
    dispatch(updateEmail({ email }));
  };

  useEffect(() => {
    dispatch(userProfile());
  }, []);

  const formik = useFormik({
    initialValues: {
      email: profileData?.email,
    },
    onSubmit: (values) => {
      updateUseEmail(profileData?.email);
    },
  });

  return (
    <Box>
      <Box color={"#F7FAFC"} mr="20%" mt="1.5%">
        Email Address
      </Box>
      <Flex>
        <Input
          type={"text"}
          height="4rem"
          width="100%"
          bg="black"
          border={"none"}
          id="email"
          onChange={formik.handleChange}
          value={ecommerce?.userProfile?.email}
          color={"white"}
        />
        <Box>
          {formik.errors.email ? (
            <Box style={{ color: "red" }}>{formik.errors.email}</Box>
          ) : null}
        </Box>
        <Button
          color={"#F7FAFC"}
          colorScheme="orange"
          w="8rem"
          h="3rem"
          type="submit"
          border={"none"}
          cursor={"pointer"}
          ml="1rem"
          mt=".5rem"
          onClick={onOpen}
        >
          <Box>
            Change
            <Box>
              <PasswordConfirmModel
                isOpen={isOpen}
                onClose={onClose}
                onOpen={onOpen}
              />
            </Box>
            {parseInt(ecommerce?.emailChangeConformPassword?.status) === 200 ? (
              <Box>
                <EmailOTP isOpen={true} onClose={onClose} />
              </Box>
            ) : (
              ""
            )}
          </Box>
        </Button>
      </Flex>
    </Box>
  );
};
