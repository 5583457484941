import {
  Box,
  Grid,
  GridItem,
  Center,
  Text,
  Image,
  VStack,
  HStack,
} from "@chakra-ui/react";
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from "@chakra-ui/react";
import { AiOutlineShopping } from "react-icons/ai";
import {
  IoReorderThreeOutline,
  IoHomeOutline,
  IoCallOutline,
  IoShirtOutline,
} from "react-icons/io5";
import { AiOutlineUser } from "react-icons/ai";
import { useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import Logo from "../assets/img/icons/logo.png";
import Account from "../assets/img/icons/account-white.png";
import { createStandaloneToast } from "@chakra-ui/react";
import { useEffect } from "react";
import { useDisclosure } from "@chakra-ui/react";
import React from "react";

export default function NavSideBar() {
  const state = useSelector((state) => state.ecommerce);
  const toast = createStandaloneToast();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();

  useEffect(() => {
    document.querySelectorAll(".chakra-alert").forEach((el) => {
      el.style.setProperty("font-size", "16px", "important");
      el.style.setProperty("padding", "10px", "important");
    });
  });

  return (
    <Box
      // boxShadow="base"
      pos="fixed"
      color={"white"}
      // bg={"#000000b5"}
      width={"100%"}
      zIndex={200}
      height="7rem"
    >
      <Grid templateColumns="repeat(16, 1fr)" fontSize={"16px"}>
        <GridItem colStart={1} mt="1rem" ml={"2rem"} alignSelf={"center"}>
          <NavLink to="#" ref={btnRef} onClick={onOpen}>
            <IoReorderThreeOutline color="white" fontSize={"4rem"} />
          </NavLink>
        </GridItem>

        <GridItem
          display={{ base: "block flex", md: "block flex", sm: "block flex" }}
          padding={{ sm: "0px" }}
          p={4}
          colStart={{ base: 14, md: 14, sm: 12 }}
          alignItems={"center"}
          justifySelf={"right"}
          colSpan={{ base: 2, md: 2, sm: 4 }}
        >
          <Box
            height={"40px"}
            borderRadius={"40px"}
            pl={8}
            pr={8}
            bg={"#141519"}
            alignItems={"center"}
            display={{ base: "block flex", md: "block flex", sm: "block flex" }}
          >
            {state.authenticate ? (
              ""
            ) : (
              <Link to="/cart" className="nav-link">
                My Cart
              </Link>
            )}
            {state.cartState.length >= 1 ? (
              <Box mt={3} ml={1}>
                <Link to="/cart" style={{ textDecoration: "none" }}>
                  <Box mb="5" display={"block flex"}>
                    {state.authenticate ? (
                      <Text
                        lineHeight={{ base: "3rem", md: "3rem", sm: "4rem" }}
                        alignSelf="center"
                        color={"white"}
                        mr={2}
                      >
                        My Cart
                      </Text>
                    ) : (
                      ""
                    )}
                    <AiOutlineShopping
                      style={{
                        width: "25px",
                        height: "25px",
                        color: "#F7FAFC",
                      }}
                    />
                    {state.cartState.length === 0 ? null : (
                      <Center
                        w="20px"
                        h="20px"
                        bg="#FFB677"
                        rounded="3xl"
                        mt={{ base: "-10px", md: "-7px", sm: "-5px" }}
                        // position="fixed"
                        // top={"2px"}
                        // ml={"28px"}
                        // left={'0rem'}
                      >
                        <Text
                          color="white"
                          fontSize="1.2rem"
                          style={{ fontWeight: "bold" }}
                        >
                          {state.cartState.length}
                        </Text>
                      </Center>
                    )}
                  </Box>
                </Link>
              </Box>
            ) : (
              <Box mt={3} ml={1}>
                <Link
                  to="#"
                  onClick={() =>
                    toast({
                      title: "Please select merch",
                      status: "warning",
                      duration: 2000,
                      isClosable: true,
                      position: "top",
                    })
                  }
                  style={{ textDecoration: "none" }}
                >
                  <Box mb="5" display={"block flex"}>
                    {state.authenticate ? (
                      <Text color={"white"} mr={2}>
                        My Cart
                      </Text>
                    ) : (
                      ""
                    )}
                    <AiOutlineShopping
                      style={{
                        width: "25px",
                        height: "25px",
                        color: "#F7FAFC",
                      }}
                    />
                    {state.cartState.length === 0 ? null : (
                      <Center
                        w="20px"
                        h="20px"
                        bg="#FFB677"
                        rounded="3xl"
                        position="absolute"
                        top={"5px"}
                        ml={"28px"}
                      >
                        <Text
                          color="white"
                          fontSize="1.2rem"
                          style={{ fontWeight: "bold" }}
                        >
                          {state.cartState.length}
                        </Text>
                      </Center>
                    )}
                  </Box>
                </Link>
              </Box>
            )}
          </Box>
          {/* <Box mt={5} ml={6}></Box> */}
        </GridItem>
      </Grid>

      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
        placement={"left"}
        padding={"20px"}
        size={"md"}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerBody bg={"#000000b5"} color={"white"}>
            <Image src={Logo} width={"50px"} mt={10} />
            <Box
              color={"#F7FAFC"}
              fontSize={"2rem"}
              fontWeight="bold"
              mt={4}
              mb={4}
            >
              Mingma David Sherpa
            </Box>
            <hr />
            <VStack fontSize="1.5rem" gap="4" alignItems={"baseline"} mt={4}>
              <HStack gap="1rem">
                <Box color={"#F7FAFC"}>
                  <IoHomeOutline size="16" color="white" />
                </Box>
                <NavLink to="/" style={{ textDecoration: "none" }}>
                  <Box color={"#F7FAFC"}>HOME</Box>
                </NavLink>
              </HStack>

              <HStack gap="1rem">
                <Box color={"#F7FAFC"}>
                  <IoCallOutline size="16" color="white" />
                </Box>
                <NavLink to="/qr" style={{ textDecoration: "none" }}>
                  <Box color={"#F7FAFC"}>CONTACT</Box>
                </NavLink>
              </HStack>

              <HStack gap="1rem">
                <Box color={"#F7FAFC"}>
                  <IoShirtOutline size="16" color="white" />
                </Box>
                <NavLink to="/merch" style={{ textDecoration: "none" }}>
                  <Box color={"#F7FAFC"}>SHOP</Box>
                </NavLink>
              </HStack>
              <HStack gap="1rem">
                <Box color={"#F7FAFC"}>
                  <NavLink to={"/user/profile"}>
                    <AiOutlineUser size="16" color="white" />
                  </NavLink>
                </Box>
                <NavLink to="/merch" style={{ textDecoration: "none" }}>
                  {state.authenticate ? (
                    <NavLink to={"/user/profile"}>
                      <Box color={"#F7FAFC"}>Profile</Box>
                    </NavLink>
                  ) : (
                    <NavLink to={"/login"}>
                      <Box color={"#F7FAFC"}>Login</Box>
                    </NavLink>
                  )}
                </NavLink>
              </HStack>
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
}
