import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  addToCart,
  checkOutInfo,
  checkOutInfoPersist,
  removeSpecificAll_products,
  removeSpecificCart,
  removeSpecificCheckOutInfoPersist,
} from "../../redux/ecommerceSlice";
import { addCartState } from "../../redux/ecommerceSlice";
import { PickAColor } from "./PickAColor";
import { Quantity } from "./Quantity";
import { Total } from "./Total";

export const MerchTotInfo = ({ value }) => {
  let checkOutIndex;
  const state = useSelector((state) => state.ecommerce);
  // use for keeping new information in "checkOutInfo" state of redux/a
  const checkOutInformation = useSelector(
    (state) => state?.ecommerce.checkOutInfo
  );

  const dispatch = useDispatch();

  // used for pre-selection of color,size etc by using redux store
  const [specificIndx, setspecificIndx] = useState(
    state?.checkOutInfoPersist.some((curr, index) => {
      checkOutIndex = index;
      return curr.product === value?.id;
    })
      ? state?.checkOutInfoPersist[checkOutIndex].specificIndx
      : 0
  );

  // used for default selection of quantity value in UI by pulling data from redux store
  const [quantity, setquantity] = useState(
    state?.checkOutInfoPersist.some((curr, index) => {
      checkOutIndex = index;
      return curr.product === value?.id;
    })
      ? state?.checkOutInfoPersist[checkOutIndex].quantity
      : 1
  );

  const [allProduct, setallProduct] = useState(false); // used for the specific dispatch of action related to all_products

  // let product_extraIdStateChangeTracker =
  //   state?.productDetail.extra[0].product_inventory;
  const [product_extraId, setProduct_extraId] = useState();

  //logic is used for limiting quantity with respect to product inventory and also this value is store in
  // in checkoutpersist of redux

  const inventory = useRef(
    state?.productDetail.extra.some((curr, index) => {
      checkOutIndex = index;
      return curr.product_inventory !== 0;
    })
      ? state?.productDetail.extra[checkOutIndex].product_inventory
      : ""
  );
  useEffect(() => {
    if (product_extraId) {
      state?.productDetail.extra.forEach((curr) => {
        if (curr.id === product_extraId) {
          inventory.current = curr.product_inventory;
        }
      });
    }
  }, [product_extraId]);

  const formik = useFormik({
    initialValues: {
      // product: value?.id,
      product: value?.id,

      product_extra: state?.checkOutInfoPersist.some((curr, index) => {
        checkOutIndex = index;
        return curr.product === value?.id;
      })
        ? state?.checkOutInfoPersist[checkOutIndex].id
        : state?.productDetail.extra[0].id,
      // used for default selection of color in UI by pulling data from redux store checkOutInfoPersist if data exist there,
      //  else color data is pulled from "value" props
      color: state?.checkOutInfoPersist.some((curr, index) => {
        checkOutIndex = index;
        return curr.product === value?.id;
      })
        ? state?.checkOutInfoPersist[checkOutIndex].color
        : state?.productDetail.extra[0].color,
      // logic same as useState of "color" in above and also here extra logic is written for size
      // selection if product_inventory = 0 , for particular size
      size: state?.checkOutInfoPersist.some((curr, index) => {
        checkOutIndex = index;
        return curr.product === value?.id;
      })
        ? state?.checkOutInfoPersist[checkOutIndex].size
        : state?.productDetail.extra.some((curr, index) => {
            checkOutIndex = index;
            return curr.product_inventory !== 0;
          })
        ? state?.productDetail.extra[checkOutIndex].size
        : "",
      quantity: 1,

      product_inventory: inventory,
    },
    onSubmit: (values) => {
      // console.log("produc extra", values.product_extra);
      // console.log("values", values);
      if (allProduct) {
        dispatch(addToCart(values));
      } else {
        dispatch(removeSpecificAll_products(value?.id));
      }
    },
  });

  return (
    <div className="merchTot__details">
      <form onSubmit={formik.handleSubmit}>
        {/* {console.log(
          "product_inventory",
          formik.values.product_inventory.current
        )}
        // {console.log("inventory", inventory)} */}
        {/* {console.log("product_extraId", product_extraId)} */}
        <PickAColor
          value={value}
          SelectedColor={formik.values.color}
          formik={formik}
          specificIndx={specificIndx}
          setspecificIndx={setspecificIndx}
          setProduct_extraId={setProduct_extraId}
          setquantity={setquantity}
        />

        <Quantity
          quantity={quantity}
          setquantity={setquantity}
          formik={formik}
          value={value}
        />
        <Total value={value} quantity={quantity} />
        {/* give special attention here as AddToCart and RemoveFromCart is confusing */}
        {state?.cartState.some((curr) => curr.id === value?.id) ? (
          <button
            className="btn-primary"
            type="submit"
            onClick={() => {
              dispatch(removeSpecificCart(value?.id));
              dispatch(removeSpecificCheckOutInfoPersist(value?.id));
              setallProduct(false);
            }}
            style={{ marginTop: "2rem" }}
          >
            Remove from cart
          </button>
        ) : (
          <button
            className="btn-primary"
            type="submit"
            onClick={() => {
              dispatch(addCartState({ id: value?.id }));
              dispatch(
                checkOutInfo({
                  ...checkOutInformation,
                  specificIndx,
                  color: formik.values.color,
                  price: value?.price,
                  gallary: value?.gallary[0].image,
                  id: value?.id,
                  size: formik.values.size,
                  product: formik.values.product,
                  quantity: quantity,
                  product_extra: formik.values.product_extra,
                  product_inventory: formik.values.product_inventory,
                })
              );
              dispatch(checkOutInfoPersist());
              setallProduct(true);
            }}
            style={{ marginTop: "2rem" }}
          >
            Add To Cart
          </button>
        )}
      </form>
    </div>
  );
};
