import { Box, Button, Center, Flex, useDisclosure } from "@chakra-ui/react";
import { Image, Input, Text } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { loginUser, googleLogin, userProfile } from "../redux/ecommerceSlice";
import * as Yup from "yup";
import { useFormik } from "formik";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { GoogleLogin } from "react-google-login";
import NavSideBar from "../components/NavSideBar";
import { ForgetPassword } from "../components/ForgetPassword";

export const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authed = useSelector((state) => state.ecommerce.loginStaue);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { state } = useLocation();

  /*   const responseFacebook = (response) => {
    dispatch(faceBookLogin({ access_token: response.accessToken }));
  };
 */
  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email format")
      .required("Please enter your email"),
    password: Yup.string().required("No password provided."),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    validateOnChange: false,
    onSubmit: (values) => {
      dispatch(loginUser(values));
    },
  });
  const responseGoogle = (response) => {
    // console.log(response);
    dispatch(googleLogin({ accessToken: response.accessToken }));
  };

  if (authed) {
    // navigate("/profile");
    navigate(state?.path || "/user/profile");
    dispatch(userProfile());
  }
  return (
    <>
      <NavSideBar />
      <Box position={"relative"}>
        <Box bg="black">
          <Image
            // boxSize={"100%"}
            src="./MingaDavid.png"
            width="100vw"
            height="99.4vh"
            objectFit="cover"
            opacity="0.25"
          />
        </Box>

        <Box
          bg="rgba(12, 0, 0, 0.600)"
          width="85%"
          height="60rem"
          position={"absolute"}
          top="9rem"
          left="7%"
          borderRadius={"2rem"}
          // opacity="0.63"
          // right="30%"
        >
          <Center
            color={"#F7FAFC"}
            fontSize="4xl"
            fontWeight={"bold"}
            mt={{ base: "6.5rem" }}
          >
            WELCOME TO MINGMA DAVID SHERPA
          </Center>
          <Center color={"#F7FAFC"} fontSize="xl" mt="0.5%">
            Login to your account and explore your Merch details.
          </Center>
          <form onSubmit={formik.handleSubmit}>
            <Center color={"#F7FAFC"} mr="32rem" mt="2rem" fontSize="2xl">
              Email
            </Center>
            <Center mt="0.7%">
              <Input
                type={"text"}
                id="email"
                height="4rem"
                width={{ base: "xl" }}
                bg="#F7FAFC"
                onChange={formik.handleChange}
                value={formik.values.email}
                placeholder="please enter Email"
              />
            </Center>
            <Center
              color={"#F7FAFC"}
              mr="30rem"
              mt={{ base: "2rem" }}
              fontSize="2xl"
            >
              Password
            </Center>

            <Center mt="0.7%">
              <Input
                type={"password"}
                id="password"
                height="4rem"
                width={{ base: "xl", md: "xl" }}
                bg="#F7FAFC"
                onChange={formik.handleChange}
                value={formik.values.password}
                placeholder="please enter password"
              />
            </Center>
            {authed === false && (
              <Center style={{ color: "red" }}>
                Invalid email or password
              </Center>
            )}
            <Center>
              {formik.errors.password ? (
                <Center style={{ color: "red" }}>
                  {formik.errors.password}
                </Center>
              ) : null}
            </Center>
            <Center
              color={"#F7FAFC"}
              ml="26rem"
              mt={{ base: "0.6rem" }}
              fontSize="xl"
              onClick={onOpen}
              cursor="pointer"
            >
              Forget password?
              <ForgetPassword isOpen={isOpen} onClose={onClose} />
            </Center>
            <Center>
              <Button
                color={"#F7FAFC"}
                colorScheme="orange"
                mt="2.5rem"
                height="3rem"
                width="8rem"
                border={"none"}
                fontSize="1.2rem"
                cursor={"pointer"}
                type="submit"
              >
                LOGIN
              </Button>
            </Center>
            <Center mt="2.5rem">
              <Flex gap={"5rem"}>
                <Box w="20rem" h="0.1rem" bg="white"></Box>
                <Box w="20rem" h="0.1rem" bg="white"></Box>
              </Flex>
            </Center>
            <Center mt="3rem">
              {/* <Button
                size="lg"
                color="black"
                colorScheme="google"
                border={"none"}
                cursor={"pointer"}
                rightIcon={
                  <FcGoogle size={20} style={{ marginLeft: "1.5rem" }} />
                }
              >
                Sign Up With Google
              </Button>*/}
              <GoogleLogin
                clientId="778133011402-gjor8f3kho0bm72q20shb1j5tjb0dgks.apps.googleusercontent.com"
                buttonText="Sign In with google"
                onSuccess={responseGoogle}
                onFailure={responseGoogle}
                cookiePolicy={"single_host_origin"}
              />
            </Center>
            {/*<Center mt="1%">
              <Button
                size="lg"
                colorScheme="facebook"
                border={"none"}
                cursor={"pointer"}
                rightIcon={
                  <FaFacebook
                    size={20}
                    style={{ marginLeft: "0.4rem" }}
                    callback={responseFacebook}
                  />
                }
              >
                Sign Up With Facebook
              </Button> 
            </Center>*/}
            <Center color={"#F7FAFC"} fontSize="2xl" mt="1.5rem">
              Don't have an account?{" "}
              <Text fontWeight={"bold"} ml="0.3rem">
                <NavLink to="/signup">SIGN UP</NavLink>
              </Text>
            </Center>
          </form>
        </Box>
      </Box>
    </>
  );
};
