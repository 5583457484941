import SectionTitle from "../components/SectionTitle";
import { Box, Grid, GridItem, Text } from "@chakra-ui/react";
import { getProducts } from "../redux/ecommerceSlice";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import MerchCard from "../components/MerchCard";
import ProductCart from "../components/ProductCart";

export default function NewArrival() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getProducts());
  }, []);
  const value = useSelector((state) => state.ecommerce.AllProduct);

  return (
    <Box bg={"#0a0909"} overflow={"hidden"}>
      <Text color={"white"} fontSize={"3.5rem"} mt="5rem">
        New Arrivals
      </Text>
      {/* <SectionTitle title={"New Arrivals"} /> */}
      <Grid templateColumns="repeat(12, 1fr)" pt={10} gap={{ base: 4, sm: 2 }}>
        {/* {value?.results?.map((curr) => {
                return (
                <GridItem colSpan={{base:3,md:3, sm:6}}>
                    <Link to={`/merch-detail/${curr.id}`} key={curr.id}>
                        <div>
                        <MerchCard
                            name={curr.name}
                            edition={curr.edition}
                            price={curr.price}
                            image={curr.image}
                        />
                        </div>
                    </Link>
                </GridItem>
                );
            })} */}
        {value?.results?.map((curr) => {
          return (
            <GridItem colSpan={{ base: 3, md: 3, sm: 6 }} key={curr.id}>
              <Link
                to={`/merch-detail/${curr.id}`}
                key={curr.id}
                style={{ textDecoration: "none" }}
              >
                <div>
                  <ProductCart data={curr} />
                </div>
              </Link>
            </GridItem>
          );
        })}
      </Grid>
    </Box>
  );
}
