import React, { useEffect } from "react";
import { Box, Button, Input, Text, useToast } from "@chakra-ui/react";
import { ModalCloseButton, ModalContent, ModalFooter } from "@chakra-ui/react";
import { Modal, ModalBody, ModalHeader, ModalOverlay } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import {
  emailChangeConformPassword,
  resetEmailChangeConformPassword,
} from "../../../redux/ecommerceSlice";
import { userProfile } from "../../../redux/ecommerceSlice";
import { EmailOTP } from "./EmailOtp";

export const PasswordConfirmModel = ({ isOpen, onClose, onOpen }) => {
  const ecommerce = useSelector((state) => state.ecommerce);
  const dispatch = useDispatch();
  const toast = useToast();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: ecommerce?.userProfile?.email,
      password: "",
    },
    onSubmit: (values) => {
      dispatch(emailChangeConformPassword(values));

      onClose(true);
    },
  });

  useEffect(() => {
    if (parseInt(ecommerce?.emailChangeConformPassword?.status) === 200) {
      toast({
        position: "top",
        isClosable: true,
        status: `success`,
        title: `You an change your email now.`,
      });
    } else if (ecommerce?.emailChangeConformPassword === false) {
      toast({
        position: "top",
        isClosable: true,
        status: `error`,
        title: `Password Wrong`,
      });
    }
  }, [ecommerce?.emailChangeConformPassword]);

  // function onconfirmHandler() {}

  useEffect(() => {
    dispatch(resetEmailChangeConformPassword());
  }, []);

  return (
    <Box w="75%" ml="5rem">
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <form onSubmit={formik.handleSubmit}>
            <ModalHeader>Confirm Password</ModalHeader>
            <ModalCloseButton border={"none"} bg="gray.300" />
            <ModalBody mt="1rem">
              <Box mt="1rem">
                <Text fontWeight={"400"} fontSize="lg" mb="0.5rem">
                  Please enter your password, to confirm this is your account ?
                </Text>
                <Input
                  type={"password"}
                  id="password"
                  height="4rem"
                  bg="#F7FAFC"
                  onChange={formik.handleChange}
                  value={formik.values.password}
                />
              </Box>
            </ModalBody>
            <ModalFooter mt="1rem">
              <Button
                color={"#F7FAFC"}
                colorScheme="blue"
                mt="2.5%"
                size="md"
                border={"none"}
                fontSize="1.2rem"
                cursor={"pointer"}
                type="submit"
                onClick={onOpen}
              >
                Confirm Password
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </Box>
  );
};
