import React from 'react'
import mapImg from '../assets/img/map.png';
import everestImg from '../assets/img/everest.png';

function PayCard() {
    return (
        <>
            <section className="payCard">
                <div className="payCard__container">
                    <div className="payCard__ship">
                        <p className="pay-title">SHIPPING DETAILS</p>
                        <p className="pay-location">JHAMSIKHEL, LALITPUR, NEPAL</p>
                        <p className="pay-medTitle" style={{textAlign: 'center', marginBottom: '2rem'}}>PIN YOUR LOCATION</p>
                        <img src={mapImg} alt="Map" className="pay__map" />
                    </div>
                    <div className="payCard__det">
                        <p className="pay-title">PAYMENT DETAILS</p>
                        <img src={everestImg} alt="" className="payCard__logo" />
                        <div className="payCard__inputGrp">
                            <input type="text" placeholder='Full Name On Card' className="payCard__input" style={{width: '70%'}} />
                            <input type="text" placeholder='Expiry' className="payCard__inputSmall" style={{width: '30%'}} />
                        </div>
                        <div className="payCard__inputGrp" style={{marginBottom: '4rem'}}>
                            <input type="text" placeholder='Card Number' className="payCard__input" style={{width: '70%'}} />
                            <input type="text" placeholder='CCV' className="payCard__inputSmall" style={{width: '30%'}} />
                        </div>


                        <div className="payCard__btnWrapper">
                            <button className="btn-color" style={{margin: 'auto'}}>PAY WITH EVEREST BANK</button>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PayCard
