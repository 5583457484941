import { Box, Grid, GridItem } from "@chakra-ui/react";
import React, { useState } from "react";
import { Header } from "../Header";
import { Sidemenu } from "../Sidemenu";
import { useDispatch, useSelector } from "react-redux";

export const SidebarAndHeader = ({ children }) => {
  const profileData = useSelector((state) => state.ecommerce.userProfile);
  const [drawer, setDrawer] = useState(false);

  return (
    <>
      <Grid
        templateColumns="repeat(24, 1fr)"
        bg={"blackAlpha.700"}
        minH={"100vh"}
      >
        <GridItem
          colSpan={{ xl: 4, md: 4, sm: drawer ? 0 : 8 }}
          h="100%"
          display={drawer ? "none" : "block"}
        >
          <Sidemenu setDrawer={setDrawer} drawer={drawer} />
        </GridItem>
        <GridItem
          colSpan={{
            base: drawer ? 24 : 20,
            md: drawer ? 24 : 20,
            sm: drawer ? 24 : 16,
          }}
          w="100%"
          h="100%!important"
        >
          <Header
            profileData={profileData}
            drawer={drawer}
            setDrawer={setDrawer}
          />
          <Box p={10}>{children}</Box>
        </GridItem>
      </Grid>
    </>
  );
};
