import { Box, Input, Text, Button } from "@chakra-ui/react";
import { InputGroup, InputRightElement } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { changePassword } from "../../../redux/ecommerceSlice";
import * as Yup from "yup";

export const PasswordChange = () => {
  const dispatch = useDispatch();
  const ecommerce = useSelector((state) => state.ecommerce);
  const [passwordNotMatch, setPasswordNotMatch] = useState(false);

  const validationSchema = Yup.object({
    old_password: Yup.string()
      .min(3, "Too Short!")
      .max(50, "Too Long!")
      .required("Please enter the old password"),
    new_password: Yup.string()
      .email("Invalid email format")
      .required("Please enter your new passsword"),
    confirm_password: Yup.number()
      .min(0, "Too Short!")
      .max(100000000000, "Too Long!")
      .required("Please enter your confirm password"),
  });

  const formik = useFormik({
    validateOnChange: false,
    initialValues: {
      old_password: "",
      new_password: "",
      confirm_password: "",
    },
    validationSchema,
    onSubmit: (values) => {
      if (values.new_password === values.confirm_password) {
        const arrangeValues = {
          old_password: values.old_password,
          new_password: values.new_password,
        };
        dispatch(changePassword(arrangeValues));
      } else {
        setPasswordNotMatch({ message: "Incorrect password" });
      }
    },
  });

  return (
    <Box>
      <Text
        mb="1rem"
        fontWeight="bold"
        color={"#F7FAFC"}
        mr="20%"
        mt="4.5%"
        fontSize="2rem"
      >
        Change password
      </Text>
      <form onSubmit={formik.handleSubmit}>
        <Box>
          <Box color={"#F7FAFC"} mr="20%" mt="1.5%">
            Old Password
          </Box>
          <Input
            type={"text"}
            height="4rem"
            width="100%"
            bg="black"
            border={"none"}
            id="old_password"
            onChange={formik.handleChange}
            value={formik.values.old_password}
            color={"white"}
          />
          {formik.errors.old_password ? (
            <Box color="red" fontSize={"xl"} mt="2px">
              {formik.errors.old_password}
            </Box>
          ) : null}
        </Box>
        <Box>
          <Box color={"#F7FAFC"} mr="20%" mt="1.5%">
            New password
          </Box>
          <Input
            type={"text"}
            height="4rem"
            width="100%"
            bg="black"
            border={"none"}
            id="new_password"
            onChange={formik.handleChange}
            value={formik.values.new_password}
            color={"white"}
          />
          {formik.errors.new_password ? (
            <Box color="red" fontSize={"xl"} mt="2px">
              {formik.errors.new_password}
            </Box>
          ) : null}
        </Box>
        <Box>
          <Box color={"#F7FAFC"} mr="20%" mt="1.5%">
            Confirm Password
          </Box>
          <Input
            type={"text"}
            height="4rem"
            width="100%"
            bg="black"
            border={"none"}
            id="confirm_password"
            onChange={formik.handleChange}
            value={formik.values.confirm_password}
            color={"white"}
          />
          {formik.errors.confirm_password ? (
            <Box color="red" fontSize={"xl"} mt="2px">
              {formik.errors.confirm_password}
            </Box>
          ) : null}

          {passwordNotMatch && (
            <Box color="red.500">
              New password and confirm password doesn't match!!
            </Box>
          )}
        </Box>
        <Box>
          <Button
            color={"#F7FAFC"}
            colorScheme="orange"
            w="12rem"
            h="4rem"
            type="submit"
            mt={4}
            border={"none"}
            cursor={"pointer"}
          >
            Change Password
          </Button>
        </Box>
      </form>
    </Box>
  );
};
