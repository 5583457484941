import React, { useEffect } from "react";
import { Map, Marker, GoogleApiWrapper, Polyline } from "google-maps-react";
import { useDispatch, useSelector } from "react-redux";
import { updateShippingAddress, userProfile } from "../redux/ecommerceSlice";

function MapProfile({
  google,
  lat,
  lng,
  setLat,
  setLng,
  setAddr,
  savelocation,
  profileData,
  setVal,
  setIsvalley,
  setSelfPick
}) {
  const userProfile = useSelector((state) => state.ecommerce.userProfile);
  const mapStyle = {
    height: "305px",
    width: "100%",
    position: "relative",
    bordeRadius: "20px",
  };

useEffect(() => {
    setLat(userProfile?.latitude?userProfile.latitude:27.7172);
    setLng(userProfile?.longitude?userProfile.longitude:85.3240);
    setAddr(userProfile?.location?userProfile.location:"Pin Point your location");
  }, []);

  const dispatch = useDispatch();
  const loading = useSelector((state) => state.ecommerce.loading);

  const updateAddress = (lat, lon, add) => {
    dispatch(updateShippingAddress({ lat, lon, add }));
    if (loading) {
      dispatch(userProfile());
    }
  };

  const mapClicked = (props, maps, cEvent) => {
    setLat(cEvent.latLng.lat());
    setLng(cEvent.latLng.lng());
    try{
      setVal(0);
      setSelfPick(false);
    }catch(err){}

    var geocoder = new google.maps.Geocoder(); // create a geocoder object
    var location = new google.maps.LatLng(
      cEvent.latLng.lat(),
      cEvent.latLng.lng()
    ); // turn coordinates into an object

    geocoder.geocode({ latLng: location }, function (results, status) {
      if (status === google.maps.GeocoderStatus.OK) {
        // if geocode success
        setAddr(results[2].formatted_address);
        try{
          setIsvalley(true);
          if(JSON.stringify(results).includes("Kathmandu") ||
          JSON.stringify(results).includes("Bhaktapur") ||
          JSON.stringify(results).includes("Lalitpur")
          ){
            setIsvalley(true);
          }else{
            setIsvalley(false);
          }
        }catch(err){}

        if (savelocation) {
          updateAddress(
            cEvent.latLng.lat(),
            cEvent.latLng.lng(),
            results[2].formatted_address
          );
        }
      }
    });
  };

  return (
    <div>
      <Map
        google={google}
        zoom={13}
        containerStyle={mapStyle}
        initialCenter={{
          lat: lat,
          lng: lng,
        }}
        streetViewControl={false}
        mapTypeControl={false}
        fullscreenControl={true}
        onClick={mapClicked}
        defaultCenter={{ lat: lat, lng: lng }}
        center={{ lat: lat, lng: lng }}
      >
        <Marker
          title={"Location Selector"}
          name={"Selector"}
          position={{ lat: lat, lng: lng }}
        />

      </Map>
    </div>
  );
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyCW4b0XIXYMIqWiMHbNxn68TGzGIfE92Ss",
})(MapProfile);
