import React from "react";
function MerchCard({ name, edition, price, image }) {
  return (
    <>
      <div className="merch__card">
        <img src={image} alt="Merch Img" className="merch__card-img" />
        <p className="merch-cate">{edition}</p>
        <p className="merch-name">{name}</p>
        <p className="merch-price">RS {price}</p>
      </div>
    </>
  );
}

export default MerchCard;
