import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import rightArr from "../../assets/img/rightArr.png";
import leftArr from "../../assets/img/leftArr.png";
import { getDetails } from "../../redux/ecommerceSlice";
import { MerchTotInfo } from "./MerchTotInfo";
import NewArrival from "../NewArrival";
import { Grid, GridItem } from "@chakra-ui/react";
import Nav from "../../components/Nav";
import NavSideBar from "../../components/NavSideBar";

function MerchTot() {
  const [imageIndex, setimageIndex] = useState(0);

  const value = useSelector((state) => state.ecommerce.productDetail);
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(getDetails(id)); //
  }, [id]);

  return (
    <>
      <NavSideBar />
      <section className="merchTot">
        <Grid templateColumns="repeat(2, 1fr)">
          <GridItem colSpan={{ base: 1, md: 1, sm: 2 }}>
            <div className="merchTot__slider">
              <div className="merchTot__slide">
                {value?.gallary.map((currImg, index) => {
                  return (
                    index === imageIndex && (
                      <img
                        src={currImg.image}
                        alt="Slide"
                        className="merchDet__slide-img"
                        key={index}
                      />
                    )
                  );
                })}
                {
                  value?.gallary.length >1
                  ?
                <div className="merchDet__slide-btn">
                  <img
                    src={rightArr}
                    alt="Arr"
                    className="merchDet__slide-arr"
                    onClick={() => {
                      if (imageIndex === 0) {
                        setimageIndex(1);
                      }
                      if (imageIndex === 1) {
                        setimageIndex(0);
                      }
                    }}
                  />
                  <img
                    src={leftArr}
                    alt="Arr"
                    className="merchDet__slide-arr"
                    onClick={() => {
                      if (imageIndex === 0) {
                        setimageIndex(1);
                      }
                      if (imageIndex === 1) {
                        setimageIndex(0);
                      }
                    }}
                  />
                </div>
                :''
                }
              </div>
            </div>
          </GridItem>

          <GridItem colSpan={{ base: 1, md: 1, sm: 2 }} p={10}>
            {value && <MerchTotInfo value={value} />}
          </GridItem>
        </Grid>
      </section>
      <section className="new-arrival-section">
        <NewArrival />
      </section>
    </>
  );
}

export default MerchTot;
