import { Box, Grid, GridItem, Text, Center } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import MerchCard from "../components/MerchCard";
import { getProducts } from "../redux/ecommerceSlice";
import NavSideBar from "../components/NavSideBar";

function Merch() {
  const dispatch = useDispatch();
  
  const value = useSelector((state) => state.ecommerce.AllProduct);

  useEffect(() => {
    dispatch(getProducts());
  }, []);

  return (
    <Box>
      <Box bg="black" pos={"absolute"} zIndex={-1} minH={'100vh'} w="100%">
        <NavSideBar />
        <Center>
          <Text
            className="merch-bgTxt"
            fontSize={{ base: "17rem", md: "17rem", sm: "10rem" }}
            zIndex={-1}
            pt={{ base: "20rem", md: "20rem", sm: "19rem" }}
            position={"fixed"}
            mt="6rem"
          >
            THE MARK
          </Text>
        </Center>

        <Grid
          pr={10}
          pl={10}
          templateColumns="repeat(4, 1fr)"
          gap={1}
          pt={{ base: "16rem", md: "16rem", sm: "14rem" }}
          zIndex={100}
          rowGap={"3rem"}
        >
          {value?.results?.map((curr, indx) => {
            return (
              <GridItem colSpan={{ base: 1, md: 1, sm: 2 }}>
                <Link
                  to={`/merch-detail/${curr.id}`}
                  key={curr.id}
                  style={{ textDecoration: "none" }}
                >
                  <div style={{ marginRight: "2rem", marginLeft: "2rem" }}>
                    <MerchCard
                      name={curr.name}
                      edition={curr.edition}
                      price={curr.price}
                      image={curr.image}
                    />
                  </div>
                </Link>
              </GridItem>
            );
          })}{" "}
        </Grid>
      </Box>
    </Box>
  );
}

export default Merch;
