import React, { useEffect, useState } from "react";

import mingmadai from "../../assets/img/mingmadai.png";
import Rectangle from "../../assets/img/Rectangle.png";
import mountain from "../../assets/img/mountain.png";
import pin from "../../assets/img/pin.png";
import cloud from "../../assets/img/cloud.png";
import ganesh from "../../assets/img/ganesh.jpg";
import {
  Box,
  chakra,
  Container,
  Grid,
  GridItem,
  HStack,
  keyframes,
  SlideFade,
  Text,
  Image,
  Heading,
} from "@chakra-ui/react";

import { motion } from "framer-motion";
// import LineChart from "./LineChart";

// import { PeakNumberOfMountain } from "./PeakNumberOfMountain";
import { LineChart } from "./LineChart";
import { useDispatch } from "react-redux";
import { getMountainInfo } from "../../redux/ecommerceSlice";
import { useSelector } from "react-redux";
import { useRef } from "react";

const animationKeyframes = keyframes`
  0%   {left:18vw; top:0px;}
  50%  { left:24vw; top:0px;}
  100%  { left:18vw; top:0px;}
`;

const cloudKeyframes = keyframes`
  0%   {left:18vw; top:4vw;}
  50%  { left:24vw; top:4vw;}
  100%  { left:18vw; top:4vw;}
`;
let mountainRangeKeyframes = keyframes`
  0%   {left:50vw; top:0vw;}
  50%  { left:51vw; top:0vw;}
  100%  { left:50vw; top:0vw;}
`;

const md = window.innerWidth < 965;
if (md) {
  mountainRangeKeyframes = keyframes`
  0%   {left:30vw; top:17vw;}
  50%  { left:32vw; top:17vw;}
  100%  { left:30vw; top:17vw;}
`;
}
// const sm = window.innerWidth < 320;
// if (sm) {
//   mountainRangeKeyframes = keyframes`
//   0%   {left:30vw; top:16vw;}
//   50%  { left:32vw; top:16vw;}
//   100%  { left:30vw; top:16vw;}
// `;
// }

const animation = `${animationKeyframes} 13s linear infinite`;
const cloudAnimation = `${cloudKeyframes} 12s ease-in-out infinite`;
const mountainAnimation = `${mountainRangeKeyframes} 8s linear infinite`;

const Mountain = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getMountainInfo());
  }, []);

  const mountainData = useSelector((state) => state.ecommerce.mountainInfo);

  const [specificIndex, setSpecificIndex] = useState(0);
  const [arrayData, setArrayData] = useState([
    150, 500, 200, 280, 200, 280, 200, 280, 200, 280, 200, 280, 200, 280, 200,
    280, 200, 280, 200, 280, 200, 280, 200, 280, 200, 280, 200, 280, 200, 280,
    200, 280, 200,
  ]);
  // console.log("specificIndex", specificIndex);
  return (
    <Box pos="relative">
      <Heading
        // as="acquireFont"
        pos="absolute"
        color="#707070"
        zIndex={"4"}
        fontSize={{
          sm: "5xl",
          md: "8xl",
          lg: "7xl",
          xl: "5xl",
        }}
        ml={{ sm: "5vw", xl: "32vw" }}
        mt={{ sm: "10vw", xl: "6vh" }}
        fontFamily="Aquire"
      >
        Youngest to Climb All 14 Peak
      </Heading>
      <Image
        src={mingmadai}
        alt="nicee"
        objectFit="cover"
        w="100vw"
        pos="absolute"
        zIndex={1}
        h={{ sm: "124rem", xl: "100vh" }}
      />
      {mountainData?.map((data, indx) => {
        return (
          <Box
            display={specificIndex === indx ? "block" : "none"}
            color="gray.500"
            fontWeight={"bold"}
            pos="absolute"
            // ml="5.9rem"
            fontSize={{ sm: "15rem", md: "20rem", xl: "15rem" }}
            // onClick={() => {
            //   setSpecificIndex(0),
            //     setArrayData([150, 500, 200, 350, 250, 320, 200, 280, 150]);
            // }}
            // display={specificIndex === 0 ? "none" : "block"}
            mt={{ sm: "90%", md: "5%", xl: "5%" }}
            left={{ sm: "25%", md: "58%", xl: "49%" }}
            // mr="34rem"
            zIndex={"auto"}
            as={motion.div}
            animation={mountainAnimation}
          >
            <SlideFade
              direction="bottom"
              // in={true}
              in={specificIndex === indx ? true : false}
              // unmountOnExit={true}
              offsetY="50px"
              transition={{ enter: { duration: 0.4 } }}
            >
              {" "}
              {+data.mountain_range}
            </SlideFade>
          </Box>
        );
      })}

      <Image
        src={Rectangle}
        alt="nicee"
        w="100vw"
        h={{ sm: "124rem", xl: "100vh" }}
        objectFit="cover"
        layout="fill"
        pos="absolute"
        zIndex={-1}
      />
      <Box pos="relative" mr={{ xl: "30%" }} zIndex={10}>
        <Box
          as={motion.div}
          animation={animation}
          pos="absolute"
          // left={"15%"}
          zIndex={10}
          // bg="green"
          mr={{ sm: "4rem", xl: "0rem" }}
          mt="8rem"
        >
          <Image
            src={cloud}
            width={{ sm: "100%", md: "60%", xl: "70%" }}
            height={"auto"}
            // height="20%"
            // w="200"
            // h="200"
            // borderWidth={5}
            // borderStyle="solid"
            // zIndex={"2"}
            // layout="fixed"
            objectFit={"contain"}
          />
        </Box>
        <Box
          as={motion.div}
          animation={cloudAnimation}
          pos="absolute"
          left={"25%"}
          zIndex={1}
          // bg="red"
          mr={{ sm: "4rem", xl: "0rem" }}
          mt="8rem"
        >
          <Image
            src={cloud}
            width={{ sm: "100%", md: "60%", xl: "70%" }}
            height={"auto"}
            // height="20%"
            // w="200"
            // h="200"
            // borderWidth={5}
            // borderStyle="solid"
            // zIndex={"2"}
            // layout="fixed"
            objectFit={"contain"}
          />
        </Box>
        <Box zIndex={2} pos={"absolute"} top={"170px"}>
          <Grid
            templateColumns={{
              md: "repeat(2, 1fr)",
              lg: "repeat(2, 1fr)",
              xl: "repeat(2, 1fr)",
            }}
            justifyItems="end"
            // gap="6px"
            pos="relative"
            mt={{ sm: "-5rem", xl: "0rem" }}
            gap="1rem"
            ml={{ xl: "25%" }}
            width={{ xl: "100%" }}
            // height={{ sm: "10%", md: "10%" }}
            // lineHeight="1rem"
          >
            <GridItem
              colSpan={1}
              w={{ sm: "100%", md: "100%", xl: "70%" }}
              padding={{ sm: "1rem", xl: "0.5rem" }}
            >
              {mountainData?.map((data, indx) => {
                return (
                  <Box
                    key={data.id}
                    display={specificIndex === indx ? "block" : "none"}
                  >
                    <SlideFade
                      direction="bottom"
                      // in={true}
                      in={specificIndex === indx ? true : false}
                      // unmountOnExit={true}
                      offsetY="50px"
                      transition={{ enter: { duration: 0.4 } }}
                    >
                      {" "}
                      <Image
                        src={data.image}
                        // borderWidth={5}
                        // borderStyle="solid"
                        objectFit={"cover"}
                        width={{ sm: "100%", lg: "40%", xl: "100%" }}
                        height={{
                          sm: "40rem",
                          md: "50rem",
                          lg: "50rem",
                          xl: "29rem",
                        }}
                        padding={{ sm: "2rem", xl: "1rem" }}
                        // layout="responsive"
                        zIndex={9}
                      />
                    </SlideFade>
                  </Box>
                );
              })}
            </GridItem>
            <GridItem
              colSpan={1}
              padding={{ sm: "1.5rem", xl: "0rem" }}
              zIndex={10}
            >
              {mountainData?.map((data, indx) => {
                return (
                  <Box
                    display={specificIndex === indx ? "block" : "none"}
                    zIndex={10}
                    padding={{ sm: "2rem", xl: "1rem" }}
                    key={data.id}
                  >
                    <SlideFade
                      direction="bottom"
                      in={specificIndex === indx ? true : false}
                      // unmountOnExit={true}
                      offsetY="50px"
                      transition={{ enter: { duration: 0.4 } }}
                      zIndex={10}
                    >
                      {" "}
                      <Text
                        color="white"
                        fontSize={{
                          sm: "6xl",
                          md: "8xl",
                          lg: "9xl",
                          xl: "5xl",
                        }}
                        mt={{ sm: "-2rem", xl: "0rem" }}
                      >
                        {data.title}
                      </Text>
                      <HStack>
                        <Image
                          src={pin}
                          // width="370%"
                          // height="20%"
                          // w="200"
                          // h="200"
                          borderWidth={5}
                          // borderStyle="solid"
                          layout="fixed"
                          objectFit={"contain"}
                        />
                        <Text
                          color="white"
                          fontSize={{
                            sm: "5xl",
                            md: "7xl",
                            lg: "6xl",
                            xl: "3xl",
                          }}
                        >
                          {data.location}
                        </Text>
                      </HStack>
                      <Text
                        color="white"
                        fontSize={{
                          sm: "4xl",
                          md: "6xl",
                          lg: "5xl",
                          xl: "3xl",
                        }}
                        mt={{ sm: "1rem", xl: "2rem" }}
                        w={{ xl: "70%" }}
                        h="20rem"
                        zIndex={10}
                        // overflow="scroll"

                        // w={{ sm: "20%", md: "40%", xl: "70%" }}
                      >
                        {data.description}
                      </Text>
                    </SlideFade>
                  </Box>
                );
              })}
            </GridItem>
          </Grid>
          <Grid templateColumns="repeat(11, 1fr)" gap={8} mt="1.2rem">
            <GridItem colStart={{ sm: 1, xl: 4 }} colSpan={8} pos="relative">
              <Box
                w={{ sm: "94vw", xl: "0vw" }}
                mt={{ sm: "-5rem", xl: "0rem" }}
                zIndex={10}
                display="fixed"
                overflowX={{ sm: "scroll", xl: "visible" }}
                // bgColor="green"

                scrollBehavior="smooth"
                id="scroll"
                // bg="red"
              >
                {mountainData && (
                  <LineChart
                    // setMountainInfo={setMountainInfo}
                    setSpecificIndex={setSpecificIndex}
                    setArrayData={setArrayData}
                    arrayData={arrayData}
                    mountainData={mountainData}
                  />
                )}
              </Box>
            </GridItem>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default Mountain;
