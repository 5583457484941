import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { ChakraProvider } from "@chakra-ui/react";
import { Routes, Route, useLocation } from "react-router-dom";
import "../src/styles/Main.scss";

import QrPage from "./pages/QrPage";
import { Signup } from "./pages/Signup/Signup";
import { MyOrders } from "./pages/MyOrders.js/MyOrders";

import { PendingOrders } from "./pages/MyOrders.js/PendingOrders";
import Cart from "./pages/Cart";

import Home from "./pages/Home";
import { Login } from "./pages/Login";
import Merch from "./pages/Merch";
import MerchDet from "./pages/MerchDet";
import MerchFin from "./pages/MerchFin";
import MerchTot from "./pages/MerchTotal/MerchTot";
import PayCard from "./pages/PayCard";
import { Profile } from "./pages/Profile/Profile";
import { OrderDetails } from "./pages/MyOrders.js/OrderDetails/OrderDetails";
import RequireAuth from "./components/WarpperComponent/RequireAuth";
import { OrderHistory } from "./pages/MyOrders.js/OrderHistory";
import { createBreakpoints } from "@chakra-ui/theme-tools";
import { extendTheme } from "@chakra-ui/react";
import { useEffect } from "react";
import NavSideBar from "./components/NavSideBar";
import Pay from "./pages/Pay/Pay";
import PurchaseDetails from "./pages/Pay/PurchaseDetails";
import PaymentSelection from "./pages/Pay/PaymentSelection";

function App() {
  const breakpoints = createBreakpoints({
    sm: "320px",
    md: "965",
    lg: "1200",
    xl: "1220",
  });

  const theme = extendTheme({
    breakpoints,
    fonts: {
      heading: "helvetica,helveticaBold",
      body: "helvetica,helveticaBold",
    },
  });
  return (
    <ChakraProvider resetCSS={false} theme={theme}>
      {window.location.href.includes("/user/")}
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        {/* <Route path="/nav" element={<NavSideBar />} /> */}
        <Route path="merch" element={<Merch />} />
        <Route path="merch-detail/:id" element={<MerchDet />} />
        <Route path="merch-total/:id" element={<MerchTot />} />
        <Route path="merch-final" element={<MerchFin />} />
        <Route path="merch-pay-detail" element={<Pay />} />
        <Route path="merch-purchase-detail" element={<PurchaseDetails />} />
        <Route path="merch-payment-selection" element={<PaymentSelection />} />
        <Route path="merch-payCard-detail" element={<PayCard />} />
        {/* <Route path="cart" element={<Cart />} /> */}
        <Route path="qr" element={<QrPage />} />
        <Route path="login" element={<Login />} />
        <Route path="signup" element={<Signup />} />
        <Route
          path="cart" //
          element={<Cart />}
        />
        {/* <Route path="profile" element={<Profile />} /> */}
        {/* <Route path="user/favourites" element={<Favourites />} /> */}
        <Route
          path="user/myOrders"
          element={
            <RequireAuth>
              <MyOrders />
            </RequireAuth>
          }
        />
        <Route
          path="user/pendingOrders"
          element={
            <RequireAuth>
              <PendingOrders />
            </RequireAuth>
          }
        />
        <Route
          path="user/orderHistory"
          element={
            <RequireAuth>
              <OrderHistory />
            </RequireAuth>
          }
        />
        <Route
          path="user/orderDetail/:uuid/"
          element={
            <RequireAuth>
              <OrderDetails />
            </RequireAuth>
          }
        />
        <Route
          path="user/profile"
          element={
            <RequireAuth>
              <Profile />
            </RequireAuth>
          }
        />
      </Routes>

      {/* <Footer /> */}
    </ChakraProvider>
  );
}

export default App;

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
