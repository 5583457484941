import React, { useEffect, useState } from "react";
import rightArr from "../assets/img/rightArr.png";
import leftArr from "../assets/img/leftArr.png";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { checkOutInfo, getDetails } from "../redux/ecommerceSlice";
import SectionTitle from "../components/SectionTitle";
import NewArrival from "./NewArrival";
import { Box, Grid, GridItem } from "@chakra-ui/react";
import Nav from "../components/Nav";
import Slider from "react-slick/lib/slider";
import NextArrow from "../components/NextArrow";
import PrevArrow from "../components/PrevArrow";
import NavSideBar from "../components/NavSideBar";

function MerchDet() {
  const [imageIndex, setimageIndex] = useState(0);

  const value = useSelector((state) => state.ecommerce.productDetail);

  const dispatch = useDispatch();
  const { id } = useParams();

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // nextArrow:<NextArrow />,
    // prevArrow:<PrevArrow/>
  };

  useEffect(() => {
    dispatch(getDetails(id));
  }, [id]);

  return (
    <Box>
      <NavSideBar />
      <section className="merchDet">
        <Grid templateColumns="repeat(2, 1fr)">
          <GridItem colSpan={{ base: 1, md: 1, sm: 2 }}>
            <div className="merchDet__slider">
              <div className="merchDet__slide">
                {value?.gallary.map((currImg, index) => {
                  return (
                    index === imageIndex && (
                      <img
                        src={currImg.image}
                        alt="Slide"
                        className="merchDet__slide-img"
                        key={index}
                      />
                    )
                  );
                })}
                {value?.gallary.length > 1 ? (
                  <div className="merchDet__slide-btn">
                    <img
                      src={rightArr}
                      alt="Arr"
                      className="merchDet__slide-arr"
                      onClick={() => {
                        if (imageIndex === 0) {
                          setimageIndex(1);
                        }
                        if (imageIndex === 1) {
                          setimageIndex(0);
                        }
                      }}
                    />

                    <img
                      src={leftArr}
                      alt="Arr"
                      className="merchDet__slide-arr"
                      onClick={() => {
                        if (imageIndex === 0) {
                          setimageIndex(1);
                        }
                        if (imageIndex === 1) {
                          setimageIndex(0);
                        }
                      }}
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </GridItem>
          <GridItem
            colSpan={{ base: 1, md: 1, sm: 2 }}
            pl={{ base: "50px", md: "50px", sm: "10px" }}
          >
            <div className="merchDet__details">
              <div className="merchDet__details-head">
                <div>
                  <p className="merchDet-title">{value?.name}</p>
                  <p className="merchDet-cate" style={{ marginTop: "2rem" }}>
                    {value?.edition}
                  </p>
                </div>
                <div className="merchDet-price"> Rs {value?.price}</div>
              </div>
              <p className="merchDet-info">Product Info</p>
              <React.Fragment>
                <div className="merchDet-listLines">
                  {
                    <div
                      dangerouslySetInnerHTML={{ __html: value?.product_info }}
                    />
                  }
                </div>
              </React.Fragment>
              <p className="merchDet-info">Shipping Info</p>
              <React.Fragment>
                <div className="merchDet-listLines">
                  {
                    <div
                      dangerouslySetInnerHTML={{ __html: value?.shipping_info }}
                    />
                  }
                </div>
              </React.Fragment>
              <Link to={`/merch-total/${id}`} key={id}>
                <button
                  className="btn-primary"
                  onClick={() =>
                    dispatch(
                      checkOutInfo({
                        name: value?.name,
                        edition: "Limited Edition",
                      })
                    )
                  }
                >
                  Purchase Now
                </button>
              </Link>
            </div>
          </GridItem>
        </Grid>
      </section>
      <section className="new-arrival-section">
        <NewArrival />
      </section>
    </Box>
  );
}

export default MerchDet;
