import { Image } from '@chakra-ui/react';
import Next from '../assets/img/next.png'

export default function NextArrow(props){
    const { className, style, onClick } = props;
    return (
        <>
            <Image position={'fixed'} margin={'265px 100px'} src={Next} onClick={onClick} style={style} className={className} zIndex={100} height={'70px'} width={'90px'} />
        </>
    );  
}