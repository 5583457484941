import { Heading } from "@chakra-ui/react";

export default function SectionTitle({ title }) {
  return (
    <Heading
      size="lg"
      m={1}
      color={"white"}
      // fontSize={{ sm: "2rem" }}
      fontSize={"xl"}
      textDecoration="none"
    >
      {title}
    </Heading>
  );
}
