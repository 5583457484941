import { configureStore } from "@reduxjs/toolkit";
import { ecommerceReducer } from "./ecommerceSlice";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import {
  createStateSyncMiddleware,
  initMessageListener,
} from "redux-state-sync";

const reduxStateSyncConfig = {
  whitelist: [
    "cartState",
    "all_products",
    "checkOutInfoPersist",
    "userAuthorization",
    "authenticate",
    "userProfile",
    "totalPriceInludingTax",
    "checkOutStatus",
  ],
  blacklist: ["persist/PERSIST", "persist/REHYDRATE"],
};

const persistConfig = {
  key: "root",
  storage,
  // whitelist: ["all_products"],
  whitelist: [
    "cartState",
    "all_products",
    "checkOutInfoPersist",
    "userAuthorization",
    "authenticate",
    "userProfile",
    "totalPriceInludingTax",
    "checkOutStatus",
  ],
};

const persistedReducer = persistReducer(persistConfig, ecommerceReducer);

export default configureStore({
  reducer: {
    ecommerce: persistedReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(createStateSyncMiddleware(reduxStateSyncConfig)),
});

// const customizedMiddleware = getDefaultMiddleware({
//   serializableCheck: false,
// });

//   middleware: getDefaultMiddleware =>
//     getDefaultMiddleware({
//       serializableCheck: false,
//     }),
