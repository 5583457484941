import React, { useEffect, useState } from "react";
// import React, { useEffect, useState } from 'react'
// import axios from 'axios';

import Button from "../components/Button";
import Snowfall from "react-snowfall";

import PlayButton from "../components/PlayButton";
import bannerVideo from "../assets/video/bannerVideo.mp4";
import { NavLink } from "react-router-dom";
import Nav from "../components/Nav";
import Slider from "react-slick/lib/slider";
import NextArrow from "../components/NextArrow";
import PrevArrow from "../components/PrevArrow";
import { getProducts, getVlogInfo } from "../redux/ecommerceSlice";
import Cancel from "../../src/assets/img/cancel.png";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ScaleFade,
  Image,
  Center,
  Box,
  Text,
  Button as ChakraButton,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";

import Shirt from "../assets/img/shirt.png";
import NavSideBar from "../components/NavSideBar";
import HomeNavBar from "../components/HomeNavBar";
import Mountain from "../components/Mountain/Mountain";

function Home() {
  /////////////////FOR YOUTUBE FETCHING
  // const [latVids, setLatVids] = useState([]);

  // useEffect(() => {
  //     // axios.get(`https://www.googleapis.com/youtube/v3/search?part=snippet&channelId=${process.env.REACT_APP_YT_CHANNEL_ID}&maxResults=10&order=date&type=video&key=${process.env.REACT_APP_YT_API_KEY}`)
  //     //     .then((res) => {
  //     //         setLatVids(res.data.items)
  //     //     })
  //     //     .catch((e) => {
  //     //         console.log(e)
  //     //     })
  // },[])

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getProducts());
    dispatch(getVlogInfo());
  }, []);
  const value = useSelector((state) => state.ecommerce.AllProduct);
  const VlogInfoFromRedux = useSelector((state) => state.ecommerce.VlogInfo);
  // console.log("VlogInfoFromRedux", VlogInfoFromRedux);
  const [disp, setDisp] = useState("0%");
  const [open, setOpen] = useState(false);

  // useEffect(() => {
  //   setTimeout(
  //     function() {
  //       setOpen(true);
  //     }, 3000);
  // }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <Box overflowX={"hidden"}>
      <Box>
        <HomeNavBar />
        <Snowfall
          style={{ position: "fixed", zIndex: "999", opacity: disp }}
          snowflakeCount={100}
          color={"#fff"}
          radius={[1.5, 5]}
          speed={[0.5, 5]}
        />
        {VlogInfoFromRedux && (
          <div className="home">
            <div className="home__banner">
              <div className="home__banner-overlay"></div>

              <video
                src={VlogInfoFromRedux[2]?.video}
                controls={false}
                className="home__banner-bgVideo"
                autoPlay
                muted
                playsInline
                loop
              ></video>

              <div className="home__banner-cta">
                <p className="banner-sm">{VlogInfoFromRedux[2].title}</p>
                <p className="banner-lg">
                  {" "}
                  {
                    <div
                      dangerouslySetInnerHTML={{
                        __html: VlogInfoFromRedux[2]?.sub_title,
                      }}
                    />
                  }
                </p>
                <p
                  className="banner-para"
                  style={{ opacity: "60%", marginBottom: "60px" }}
                >
                  {VlogInfoFromRedux[2].description}
                </p>
                <Button vidLink={VlogInfoFromRedux[2].link} />
              </div>

              <div className="home__banner-content">
                <div className="home__banner-videos">
                  <a
                    target="blank"
                    href={VlogInfoFromRedux[1].link}
                    className="home__banner-video"
                  >
                    <PlayButton />
                    <p className="banner-videoTitle">
                      {VlogInfoFromRedux[1].title}
                    </p>
                  </a>
                  <a
                    target="blank"
                    href={VlogInfoFromRedux[0].link}
                    className="home__banner-video"
                  >
                    <PlayButton />
                    <p className="banner-videoTitle">
                      {VlogInfoFromRedux[0].title}
                    </p>
                  </a>
                </div>
                {/* <div className="home__banner-socials">
                <div className="home__banner-social">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="11.755"
                    height="46.656"
                    viewBox="0 0 11.755 46.656"
                  >
                    <path
                      id="Path_31"
                      data-name="Path 31"
                      d="M-10,0,0,11.5V46"
                      transform="translate(10.755 0.656)"
                      fill="none"
                      stroke="#fea128"
                      stroke-width="2"
                    />
                  </svg>
                  <div className="home__banner-social--text">
                    <p className="banner-socialTitle">Instagram</p>
                    <a
                      href="https://www.instagram.com/mingma_david_sherpa/"
                      target="blank"
                      className="banner-socialLink"
                    >
                      @Mingmadavidsherpa
                    </a>
                  </div>
                </div>
                <div className="home__banner-social">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="11.755"
                    height="46.656"
                    viewBox="0 0 11.755 46.656"
                  >
                    <path
                      id="Path_31"
                      data-name="Path 31"
                      d="M-10,0,0,11.5V46"
                      transform="translate(10.755 0.656)"
                      fill="none"
                      stroke="#fea128"
                      stroke-width="2"
                    />
                  </svg>
                  <div className="home__banner-social--text">
                    <p className="banner-socialTitle">Facebook</p>
                    <a
                      href="https://www.facebook.com/mingmadavidsherpa"
                      target="blank"
                      className="banner-socialLink"
                    >
                      @Mingmadavidsherpa
                    </a>
                  </div>
                </div>
                {window.innerWidth >= 500 ? (
                  <div className="home__banner-social">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="11.755"
                      height="46.656"
                      viewBox="0 0 11.755 46.656"
                    >
                      <path
                        id="Path_31"
                        data-name="Path 31"
                        d="M-10,0,0,11.5V46"
                        transform="translate(10.755 0.656)"
                        fill="none"
                        stroke="#fea128"
                        stroke-width="2"
                      />
                    </svg>
                    <div className="home__banner-social--text">
                      <p className="banner-socialTitle">Twitter</p>
                      <a
                        href="https://twitter.com/mingma_david"
                        target="blank"
                        className="banner-socialLink"
                      >
                        @Mingmadavidsherpa
                      </a>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div> */}
              </div>
            </div>
          </div>
        )}

        <ScaleFade initialScale={0} in={open}>
          <Modal
            isOpen={open}
            onClose={() => setOpen(false)}
            size={"full"}
            height="100vh"
            pt={4}
          >
            <ModalOverlay />
            <ModalContent>
              <Image
                onClick={() => setOpen(false)}
                cursor={"pointer"}
                src={Cancel}
                height={"40px"}
                width={"40px"}
                right={"0px"}
                m={"40px"}
                pos={"absolute"}
                zIndex={1}
              />
              <Slider {...settings}>
                {value?.results?.map((curr) => {
                  return (
                    <Box
                      bg="#121212"
                      color="#B5B3B3"
                      textAlign={"center"}
                      h={"100vh"}
                    >
                      <ModalHeader fontSize={"2xl"}>Merch By</ModalHeader>
                      <ModalHeader fontSize={"4xl"} pt="0px">
                        MINGMA DAVID SHERPA
                      </ModalHeader>
                      <Center>
                        <Image
                          objectFit={"cover"}
                          src={curr.image}
                          width="400px"
                          height={"500px"}
                        />
                      </Center>
                      <ModalHeader fontSize={"2xl"} pb={0}>
                        {curr.name}
                      </ModalHeader>
                      <ModalHeader fontSize={"2xl"}>{curr.edition}</ModalHeader>
                      <Center mt={2}>
                        <NavLink to={"/merch"}>
                          <ChakraButton
                            _hover={{
                              background: "#f6f6f6",
                              color: "#262626",
                            }}
                            to="/merch"
                            fontSize={"xl"}
                            borderColor={"#262626"}
                            bg={"#262626"}
                            color="#B5B3B3"
                            w={"fit-content"}
                          >
                            Available on 06.04.22
                          </ChakraButton>
                        </NavLink>
                      </Center>
                    </Box>
                  );
                })}
              </Slider>
            </ModalContent>
          </Modal>
        </ScaleFade>
      </Box>
      <Mountain />
    </Box>
  );
}

export default Home;
