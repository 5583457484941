import {
  Box,
  Button,
  Center,
  Grid,
  GridItem,
  HStack,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { TrackOrders } from "../../../components/TrackOrders";
import { SidebarAndHeader } from "../../../components/WarpperComponent/SidebarAndHeader";
import { DetailsInfo } from "./DetailsInfo";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { orderDetail, refreshToken } from "../../../redux/ecommerceSlice";
import { useEffect } from "react";
import Loading from "../../../components/Loading";

export const OrderDetails = () => {
  const dispatch = useDispatch();
  const orderDetailData = useSelector((state) => state.ecommerce.orderDetail);
  const { uuid } = useParams();

  const loading = useSelector((state) => state.ecommerce.loading);

  useEffect(() => {
    dispatch(orderDetail(uuid));
    dispatch(refreshToken({ refresh: localStorage.getItem("refresh") }));
  }, []);

  return (
    <SidebarAndHeader>
      {/* <TrackOrders /> */}
      {loading ? <Loading /> : ""}
      <Box position={"absolute"}>
        <Box
          borderRadius={"2rem"}
          position={"relative"}
          color="#F7FAFC"
          bg="#0B0B0B"
          p={"40px 40px 40px 40px"}
        >
          <Text fontSize={"2.5rem"}>Order Details</Text>
          {/* <Button
            position={"absolute"}
            right="20px"
            top="20px"
            border={"none"}
            bg="green.500"
            px="2rem"
            color="#F7FAFC"
          >
            PAID
          </Button> */}
          <Grid gap={10} templateColumns="repeat(3, 1fr)">
            <GridItem colSpan={1}>
              <Box pt={10}>
                {orderDetailData?.all_product?.map((item, i) => (
                  <VStack mt={2} alignItems={"flex-start"}>
                    <Box>
                      <HStack gap="2rem">
                        <Center
                          w="7rem"
                          h="7rem"
                          bg="gray.900"
                          borderRadius={"1rem"}
                        >
                          <Image src={item.image} w="5rem" h="5rem" />
                        </Center>
                        <Box>
                          {" "}
                          <Box color="#F7FAFC" fontSize={"1.5rem"}>
                            {item.product}
                          </Box>
                          <Box color="#F7FAFC" fontSize={"1rem"}>
                            {item.product_edition}
                          </Box>
                          <Box color="#F7FAFC" fontSize={"1rem"}>
                            Color : {item.color}
                          </Box>
                          <Box color="#F7FAFC" fontSize={"1.3rem"}>
                            Rs {item.price} x {item.quantity}
                          </Box>
                        </Box>
                      </HStack>
                    </Box>
                    <Box bg="gray.900" w="100%" h="0.2rem"></Box>
                  </VStack>
                ))}
              </Box>
            </GridItem>
            <GridItem colSpan={2}>
              <DetailsInfo orderDetailData={orderDetailData} />
            </GridItem>
          </Grid>
        </Box>
      </Box>
    </SidebarAndHeader>
  );
};
