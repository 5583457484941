import React from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  checkOutInfoPersist,
  updateAddTocart,
} from "../../redux/ecommerceSlice";

export const Quantity = ({ quantity, setquantity, formik, value }) => {
  const dispatch = useDispatch();
  const checkOutInfo = useSelector(
    (state) => state.ecommerce.checkOutInfoPersist
  );
  const all_products = useSelector((state) => state.ecommerce.all_products);
  // used for updating the quantity , after adding the product in cart
  function changeQuantity(increaseOrDecrease) {
    let checkOutIndex;

    if (
      checkOutInfo?.some((curr, index) => {
        checkOutIndex = index;
        console.log("checkOutIndex from some", curr.product === value?.id);
        return curr.product === value?.id;
      })
    ) {
      console.log("checkOutIndex", checkOutIndex);
      let updateSize = checkOutInfo?.map((curr, indx) => {
        // console.log("checkOutIndex", checkOutIndex);
        if (indx === checkOutIndex && increaseOrDecrease === "+") {
          return { ...curr, quantity: quantity + 1 };
        } else if (indx === checkOutIndex && increaseOrDecrease === "-") {
          return { ...curr, quantity: quantity === 1 ? 1 : quantity - 1 };
        } else {
          return curr;
        }
      });
      // console.log("a", updateSize);

      dispatch(checkOutInfoPersist(updateSize));
    }
  }
  function changeQuantityOFAll_products(increaseOrDecrease) {
    let checkOutIndex;

    if (
      all_products?.some((curr, index) => {
        checkOutIndex = index;

        return curr.product === value?.id;
      })
    ) {
      let updateSize = all_products?.map((curr, indx) => {
        // console.log("checkOutIndex", checkOutIndex);
        if (indx === checkOutIndex && increaseOrDecrease === "+") {
          return { ...curr, quantity: quantity + 1 };
        } else if (indx === checkOutIndex && increaseOrDecrease === "-") {
          return { ...curr, quantity: quantity === 1 ? 1 : quantity - 1 };
        } else {
          return curr;
        }
      });
      // console.log("a", updateSize);

      dispatch(updateAddTocart(updateSize));
    }
  }

  return (
    <div>
      {" "}
      {/* {console.log("quantity", quantity)} */}
      <p style={{ marginBottom: "3.5rem" }}>
        <span className="merchTot-titleReg" style={{ marginRight: "20px" }}>
          Quantity
        </span>
        <span className="merchTot-title">
          <span
            onClick={() => {
              setquantity(quantity - 1);
              formik.setFieldValue("quantity", quantity - 1);
              changeQuantity("-");
              changeQuantityOFAll_products("-");
            }}
            style={{
              fontSize: "3.5rem",
              marginRight: "4px",
              cursor: "pointer",
            }}
          >
            -
          </span>{" "}
          {quantity === 0 ? setquantity(1) : quantity}
          <span
            onClick={() => {
              if (quantity < formik.values.product_inventory.current) {
                setquantity(quantity + 1); //
                changeQuantity("+");
                changeQuantityOFAll_products("+");
                formik.setFieldValue("quantity", quantity + 1);
                // console.log("quantity", quantity);
              }
            }}
            style={{ fontSize: "2.5rem", cursor: "pointer" }}
          >
            {" "}
            +
          </span>
        </span>
      </p>
    </div>
  );
};
