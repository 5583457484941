import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";

const baseUrl = "https://api.mingmadavidsherpa.com";

export const getMountainInfo = createAsyncThunk(
  "/api/mountain",
  async (uuid, thunkAPI) => {
    const res = await axios.get(`${baseUrl}/api/mountain/`);
    return res.data;
  }
);
export const getVlogInfo = createAsyncThunk(
  "/api/project",
  async (uuid, thunkAPI) => {
    const res = await axios.get(`${baseUrl}/api/project/`);
    return res.data;
  }
);
// const baseUrl = "http://192.168.1.71:4000";
export const getProducts = createAsyncThunk(
  "api/all-product",
  async (thunkAPI) => {
    const res = await axios.get(`${baseUrl}/api/all-product/`);
    return res.data;
  }
);
export const getDetails = createAsyncThunk(
  "api/product-detail",
  async (id, thunkAPI) => {
    const res = await axios.get(
      `${baseUrl}/api/product-with-size-color/${id}/`
    );
    return res.data;
  }
);
export const getShippingAddress = createAsyncThunk(
  "api/shipping-address/",
  async (id, thunkAPI) => {
    const res = await axios.get(`${baseUrl}/api/shipping-address/`);
    return res.data;
  }
);
// khalti payment
export const postCheckOut = createAsyncThunk(
  "/api/order/payment-khalti/",
  async (values, { rejectWithValue }) => {
    try {
      const res = await axios.post(
        `${baseUrl}/api/order/payment-khalti/`,
        values,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
        }
      );
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);
// cash on delivery
export const postCheckOutCashOnDelivery = createAsyncThunk(
  "/order/cash-on-delivery/",
  async (values, { rejectWithValue }) => {
    try {
      const res = await axios.post(
        `${baseUrl}/api/order/cash-on-delivery/`,
        values,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
        }
      );
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);
export const postOtpApi = createAsyncThunk(
  "api/send-otp/",
  async (values, thunkAPI) => {
    const res = await axios.post(`${baseUrl}/api/send-otp/`, values);
    return res.data;
  } //
);
export const forgetPassword = createAsyncThunk(
  "api/forget-password/",
  async (values, thunkAPI) => {
    const res = await axios.post(
      `${baseUrl}/api/change-forget-password/`,
      values
    );
    return res.data.data;
  } //
);

export const createUser = createAsyncThunk(
  "api/create-user/",
  async (values, thunkAPI) => {
    const res = await axios.post(`${baseUrl}/api/create-user/`, values);
    return res.data;
  } //
);
export const automaticUserCreate = createAsyncThunk(
  "api/automatic-user-create/",
  async (values, { rejectWithValue }) => {
    try {
      const res = await axios.post(
        `${baseUrl}/api/automatic-user-create/`,
        values
      );
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  } //
);
export const loginUser = createAsyncThunk(
  "api/login-user/",
  async (values, thunkAPI) => {
    const res = await axios.post(`${baseUrl}/api/login-user/`, values);
    return res.data;
  }
);
export const emailChangeConformPassword = createAsyncThunk(
  "api/emailChange-conformPassword-user/",
  async (values, thunkAPI) => {
    const res = await axios.post(`${baseUrl}/api/login-user/`, values);
    return res;
  }
);
export const refreshToken = createAsyncThunk(
  "api/refresh/",
  async (values, thunkAPI) => {
    const res = await axios.post(`${baseUrl}/api/refresh/`, values);
    return res.data;
  } //
);
// for logged in users
export const userProfile = createAsyncThunk(
  "api/user-profile/",
  async (values, thunkAPI) => {
    const res = await axios.get(`${baseUrl}/api/user-profile/`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access")}`,
      },
    });
    return res.data;
  }
);
export const googleLogin = createAsyncThunk(
  "api/google-login/",
  async (values, thunkAPI) => {
    const res = await axios.post(`${baseUrl}/api/google-login/`, {
      access_token: values.accessToken,
    });
    // console.log("google data", values.accessToken);
    return res.data;
  }
);

export const updateUserProfile = createAsyncThunk(
  "api/update-profile/",
  async (values, { dispatch }) => {
    const formData = new FormData();
    formData.append("profile", values.file);
    formData.append("full_name", values.full_name);
    formData.append("phone", values.phone);

    const res = await axios.put(`${baseUrl}/api/update-profile/`, formData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access")}`,
      },
    });
    dispatch(userProfile());
    return res.data;
  }
);

export const updateEmail = createAsyncThunk(
  "api/change-email/",
  async (values, thunkAPI) => {
    const res = await axios.put(`${baseUrl}/api/change-email/`, values, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access")}`,
      },
    });
    return res.data.data;
  }
);
export const changePassword = createAsyncThunk(
  "api/change-password/",
  async (values, thunkAPI) => {
    const res = await axios.put(`${baseUrl}/api/change-password/`, values, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access")}`,
      },
    });
    return res.data.data;
  }
);
export const userOrderDetails = createAsyncThunk(
  "api/order-detail/",
  async (values, { dispatch, rejectWithValue }) => {
    try {
      const res = await axios.get(`${baseUrl}/api/order-detail/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      });
      return res.data;
    } catch (err) {
      // console.log(err.response);
      return rejectWithValue(err.response);
    }
  }
);
export const orderDetail = createAsyncThunk(
  "api/order-detai-each-order/",
  async (uuid, thunkAPI) => {
    const res = await axios.get(
      `${baseUrl}/api/order-detail-each-order/${uuid}/`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      }
    );
    return res.data.data;
  }
);
export const allPendingOrders = createAsyncThunk(
  "api/pending-order/",
  async (value, thunkAPI) => {
    const res = await axios.get(`${baseUrl}/api/pending-order/`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access")}`,
      },
    });
    // console.log(res.data);
    return res.data.data;
  }
);
export const allOrderHistory = createAsyncThunk(
  "api/order-history/",
  async (value, thunkAPI) => {
    const res = await axios.get(`${baseUrl}/api/order-history/`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access")}`,
      },
    });

    return res.data.data;
  }
);
export const updateShippingAddress = createAsyncThunk(
  "api/add-user-location/",
  async (value, thunkAPI) => {
    const res = await axios.post(
      `${baseUrl}/api/add-user-location/`,
      {
        latitude: value.lat,
        longitude: value.lon,
        location: value.add,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      }
    );
    // console.log(res.data);
    return res.data;
  }
);
export const ecommerceSlice = createSlice({
  name: "ecommerce",
  initialState: {
    all_products: [],
    cartState: [],
    checkOutInfoPersist: [],
    create_User: "not sucessful",
    authenticate: false,
    loading: false,
    automaticUserCreate: null,
    loginStaue: null,
  },
  reducers: {
    addToCart: (state, { payload }) => {
      state.all_products.push(payload);
    },
    updateAddTocart: (state, { payload }) => {
      state.all_products = payload;
    },

    // used for removeTocart and AddToCart logic
    addCartState: (state, { payload }) => {
      state.cartState.push(payload);
    },
    removeSpecificCart: (state, { payload }) => {
      state.cartState = state?.cartState.filter(
        (curr) => curr.id !== parseInt(payload)
      );
    },
    // used for removing specific all_products
    removeSpecificAll_products: (state, { payload }) => {
      state.all_products = state?.all_products.filter(
        (curr) => curr.product !== parseInt(payload)
      );
    },
    checkOutInfo: (state, { payload }) => {
      state.checkOutInfo = payload; //
    },
    //checkOutInfo storing in local storage to use its information in checkout page
    checkOutInfoPersist: (state, { payload }) => {
      if (payload) {
        state.checkOutInfoPersist = payload;
      } else {
        state.checkOutInfoPersist.push(state.checkOutInfo);
      }
    },
    clearCart: (state) => {
      state.checkOutInfoPersist = [];
      state.checkOutInfoPersist.length = 0;
      state.cartState = [];
      state.totalPriceInludingTax.totalPrice = 0;
      state.all_products = [];
    },
    removeSpecificCheckOutInfoPersist: (state, { payload }) => {
      // state.checkOutInfoPersist = payload;
      state.checkOutInfoPersist = state?.checkOutInfoPersist.filter(
        (curr) => curr.id !== parseInt(payload)
      );
    },
    signUpInfo: (state, { payload }) => {
      // state.checkOutInfoPersist = payload;
      state.signUpInfo = payload;
    },
    loading: (state, { payload }) => {
      // state.checkOutInfoPersist = payload;
      state.loading = payload;
    },
    totalPriceInludingTax: (state, { payload }) => {
      // state.checkOutInfoPersist = payload;
      state.totalPriceInludingTax = payload;
    },
    resetEmailChangeConformPassword: (state, { payload }) => {
      // state.checkOutInfoPersist = payload;
      state.emailChangeConformPassword = null;
    },
    resetautomaticUserCreateState: (state, { payload }) => {
      // state.checkOutInfoPersist = payload;
      state.automaticUserCreate = null;
    },
  },
  extraReducers: {
    [getProducts.fulfilled]: (state, { payload }) => {
      state.AllProduct = payload;
    },
    [getMountainInfo.fulfilled]: (state, { payload }) => {
      state.mountainInfo = payload;
    },
    [getVlogInfo.fulfilled]: (state, { payload }) => {
      state.VlogInfo = payload;
    },
    [getDetails.fulfilled]: (state, { payload }) => {
      state.productDetail = payload;
    },
    [postOtpApi.fulfilled]: (state, { payload }) => {
      state.Otp_Api = payload;
    },

    [automaticUserCreate.fulfilled]: (state, { payload }) => {
      state.automaticUserCreatePayload = payload;
      state.automaticUserCreate = true;
      state.authenticate = true;
      localStorage.setItem("access", payload.access);
      localStorage.setItem("refresh", payload.refresh);
    },
    [automaticUserCreate.rejected]: (state, { payload }) => {
      state.automaticUserCreate = false;
      state.automaticUserCreatePayload = payload;
    },
    [createUser.fulfilled]: (state, { payload }) => {
      state.create_User = "sucessful";
      state.userAuthorization = payload;
    },
    [loginUser.fulfilled]: (state, { payload }) => {
      state.authenticate = true;
      state.loginStaue = true;
      state.checkOutStatus = "done";

      localStorage.setItem("access", payload.access);
      localStorage.setItem("refresh", payload.refresh);
      state.userAuthorization = payload;
    },
    [loginUser.rejected]: (state, { payload }) => {
      state.loginStaue = false;
    },
    [emailChangeConformPassword.fulfilled]: (state, { payload }) => {
      state.emailChangeConformPassword = payload;
    },
    [emailChangeConformPassword.rejected]: (state, { payload }) => {
      state.emailChangeConformPassword = false;
    },
    [userProfile.fulfilled]: (state, { payload }) => {
      state.userProfile = payload;
    },
    [updateEmail.fulfilled]: (state, { payload }) => {
      state.updateEmail = payload;
    },
    [changePassword.fulfilled]: (state, { payload }) => {
      state.changePassword = payload;
    },
    [userOrderDetails.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.checkOutStatus = "done";
      state.userOrderDetails = payload;
    },
    [userOrderDetails.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [userOrderDetails.rejected]: (state, { payload }) => {
      state.loading = false;
      state.checkOutStatus = "rejected";
      // console.log("rejectedvalue", payload);
      state.userOrderDetails = payload;
    },
    [orderDetail.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.orderDetail = payload;
    },
    [orderDetail.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [allPendingOrders.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.allPendingOrders = payload;
    },
    [allPendingOrders.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [allOrderHistory.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.allOrderHistory = payload;
    },
    [allOrderHistory.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [updateShippingAddress.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.updateShippingAddress = payload;
    },
    [updateShippingAddress.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [updateUserProfile.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.userProfile = payload;
    },
    [updateUserProfile.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [googleLogin.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.authenticate = true;
      state.checkOutStatus = "done";
      localStorage.setItem("access", payload.data.access);
      localStorage.setItem("refresh", payload.data.refresh);
      state.userAuthorization = payload;
    },
    [googleLogin.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [refreshToken.fulfilled]: (state, { payload }) => {
      state.authenticate = true;
      localStorage.setItem("access", payload.access);
      localStorage.setItem("refresh", payload.refresh);

      state.userAuthorization = payload;
    },
    [postCheckOut.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.checkOutStatus = "success";
    },
    [postCheckOut.pending]: (state, { payload }) => {
      state.checkOutStatus = "loading";
      state.loading = true;
    },
    [postCheckOut.rejected]: (state, { payload }) => {
      state.checkOutStatus = "rejected";
      state.loading = false;
      state.postCheckOut = payload;
    },
    [getShippingAddress.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.getShippingAddress = payload;
    },
    [getShippingAddress.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [postCheckOutCashOnDelivery.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.checkOutStatus = "success";
    },
    [postCheckOutCashOnDelivery.rejected]: (state, { payload }) => {
      state.loading = false;
      state.checkOutStatus = "rejected";
    },
    [postCheckOutCashOnDelivery.pending]: (state, { payload }) => {
      state.checkOutStatus = "loading";
      state.loading = true;
    },
  },
});
export const ecommerceReducer = ecommerceSlice.reducer;
export const {
  addToCart,
  updateAddTocart,
  addCartState,
  removeSpecificCart,
  removeSpecificAll_products,
  checkOutInfo,
  checkOutInfoPersist,
  removeSpecificCheckOutInfoPersist,
  signUpInfo,
  loading,
  clearCart,
  totalPriceInludingTax,
  resetEmailChangeConformPassword,
  resetautomaticUserCreateState,
} = ecommerceSlice.actions;
