import { Box, Image, Center } from "@chakra-ui/react";
import SectionTitle from "../components/SectionTitle";
import MainTitle from "./MainTitle";

export default function ProductCart({ data }) {
  return (
    <Box bg={"#101114"} p={4} borderRadius={10}>
      <Image
        src={data.image}
        width={"100%"}
        height={{ base: "220px", md: "220px", sm: "130px" }}
        objectFit={"contain"}
        borderRadius={10}
      />
      <Center mt={8} mb={0}>
        <SectionTitle title={data.edition} />
      </Center>
      <Center mb={0}> 
        <MainTitle title={data.name} />
      </Center>
      <Center>
        <SectionTitle title={`Rs ${data.price}`} />
      </Center>
    </Box>
  );
}
