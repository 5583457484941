import { EditIcon } from "@chakra-ui/icons";
import { Box, Flex, HStack } from "@chakra-ui/react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { refreshToken, userProfile } from "../../redux/ecommerceSlice";
import { useEffect } from "react";
import Account from "../../assets/img/icons/account.png";

import { Form } from "./Form";

import { SidebarAndHeader } from "../../components/WarpperComponent/SidebarAndHeader";

export const Profile = () => {
  // console.log("localStorage.getItem refresh", localStorage.getItem("refresh"));

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(userProfile());
    dispatch(refreshToken({ refresh: localStorage.getItem("refresh") }));
  }, []);
  // console.log("localStorage.getItem", localStorage.getItem("access"));
  return (
    <SidebarAndHeader>
      <Form />
    </SidebarAndHeader>
  );
};
