import React from 'react'

function Button({vidLink}) {
    return (
        <>
            <a href= {vidLink} target= "blank" className="btn__header">
                Watch Now
            </a>
        </>
    )
}

export default Button
