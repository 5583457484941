import { Grid, GridItem, Text } from "@chakra-ui/react";
import React from "react";

export const Total = ({ value, quantity }) => {
  return (
    <div>
      <p className="merchTot-title">TOTAL</p>
      <Grid templateColumns="repeat(2, 1fr)">
        <GridItem colSpan={{ base: 1, md: 1, sm: 1 }} alignSelf={"center"}>
          <Text fontSize={{ base: "2xl",md:"2xl", sm: "4xl" }} color="white" >
            {value?.name} x {quantity}
          </Text>
        </GridItem>
        <GridItem colSpan={{ base: 1, md: 1, sm: 1 }} alignSelf={"center"}>
          <Text
            fontSize={{ base: "2xl",md:"2xl", sm: "4xl" }}
            color="white"
            textAlign={"left"}
          >
            Rs {value?.price === 1 ? value?.price : quantity * value?.price}
          </Text>
        </GridItem>
      </Grid>

      {/* <Grid templateColumns="repeat(2, 1fr)">
        <GridItem colSpan={{ base: 1, md: 1, sm: 1 }} alignSelf={"center"}>
          <Text fontSize={{ base: "3xl", sm: "4xl" }} color="white">
            Tax 13%
          </Text>
        </GridItem>
        <GridItem colSpan={{ base: 1, md: 1, sm: 1 }} alignSelf={"center"}>
          <Text
            fontSize={{ base: "3xl", sm: "4xl" }}
            color="white"
            textAlign={"left"}
            // className="merchTot-title"
          >
            Rs{" "}
            {value?.price === 1
              ? value?.price * 0.13
              : quantity * (value?.price * 0.13)}
          </Text>
        </GridItem>
      </Grid> */}
      {/* <Grid templateColumns="repeat(2, 1fr)">
        <GridItem colSpan={{ base: 1, md: 1, sm: 1 }} alignSelf={"center"}>
          <Text fontSize={{ base: "3xl", sm: "4xl" }} color="white">
            Total to be paid
          </Text>
        </GridItem>
        <GridItem colSpan={{ base: 1, md: 1, sm: 1 }} alignSelf={"center"}>
          <Text
            fontSize={{ base: "3xl", sm: "4xl" }}
            color="white"
            textAlign={"left"}
            // className="merchTot-title"
          >
            Rs {quantity * value?.price + quantity * (value?.price * 0.13)}
          </Text>
        </GridItem>
      </Grid> */}
    </div>
  );
};
