import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { PickASize } from "./PickASize";
import { AiFillCheckCircle } from "react-icons/ai";
import { checkOutInfoPersist } from "../../redux/ecommerceSlice";

export const PickAColor = ({
  value,
  SelectedColor,
  formik,
  specificIndx,
  setspecificIndx,
  setProduct_extraId,
  setquantity,
}) => {
  let uniqueColor;
  let specificSizeArray = [];
  const checkOutInfo = useSelector(
    (state) => state.ecommerce.checkOutInfoPersist
  );

  let specificColor;
  let test1;
  const dispatch = useDispatch();
  function colorAndSizeArrange() {
    const arryValue = value?.extra?.map((curr) => {
      return curr.color;
    });
    uniqueColor = [...new Set(arryValue)];
    // console.log("arryValue", uniqueColor);
  }
  colorAndSizeArrange();
  function test() {
    checkOutInfo?.forEach((curr, index) => {
      if (curr.product === value?.id) {
        // console.log(curr.product);
        // console.log(value?.id);
        test1 = curr.specificIndx;
      }
    });
  }
  // test();
  // console.log("curr.product === value?.id;", test1);
  // used for updating the color , after adding the product in cart
  function changeColor() {
    let checkOutIndex;
    if (
      checkOutInfo?.some((curr, index) => {
        checkOutIndex = index;
        return curr.product === value?.id;
      })
    ) {
      let updateColor = checkOutInfo?.map((curr, indx) => {
        // console.log("specificColor", specificColor);
        if (indx === checkOutIndex) {
          return { ...curr, color: specificColor };
        }
        return curr;
      });
      // console.log("a", updateColor);

      dispatch(checkOutInfoPersist(updateColor));
    }
  }
  return (
    <div>
      <p className="merchTot-title">PICK A COLOR</p>
      <div className="merchTot__colors">
        {/* this logic is used for picking of the size according to color */}
        {uniqueColor?.map((color, indx) => {
          // console.log(color);
          return (
            <div
              // className="merchTot__color-selected"
              className={
                color === SelectedColor
                  ? "merchTot__color-selected"
                  : "merchTot__color"
              }
              style={{ backgroundColor: color, textAlign: "right" }}
              key={indx}
              onClick={() => {
                setspecificIndx(indx);
                formik.setFieldValue("color", color);
                specificColor = color;
                // console.log("bibkhnknlhn", specificIndx);
                changeColor();
              }}
            >
              <AiFillCheckCircle
                fontSize={"20px"}
                color="green"
                style={color === SelectedColor ? {} : { display: "none" }}
              />

              {/* this logic is used for picking of the size according to color */}
              {value?.extra?.forEach((curr) => {
                if (specificIndx === indx && curr.color === color) {
                  specificSizeArray.push({
                    size: curr.size,
                    id: curr.id,
                    product_inventory: curr.product_inventory,
                  });
                }
              })}
            </div>
          );
        })}
      </div>

      <PickASize
        specificSizeArray={specificSizeArray}
        specificIndx={specificIndx}
        formik={formik}
        value={value}
        setProduct_extraId={setProduct_extraId}
        setquantity={setquantity}
      />
    </div>
  );
};
