import React, { useEffect } from 'react'
import backBg from '../assets/img/mountBack.png';
import frontBg from '../assets/img/mountFront.png';

import logo from '../assets/img/icons/logo.png';
import eliteLogo from '../assets/img/icons/eliteLogo.png';
import fbLogo from '../assets/img/icons/fbLogo.png';
import igLogo from '../assets/img/icons/igLogo.png';
import peakLogo from '../assets/img/icons/peakLogo.png';
import twLogo from '../assets/img/icons/twLogo.png';
import ytLogo from '../assets/img/icons/ytLogo.png';

import Snowfall from 'react-snowfall';

import arrBoard from '../assets/img/arrBoard.png';
import dialogueImg from '../assets/img/dialogue.png';

import gsap from 'gsap';
import Nav from '../components/Nav';
import NavSideBar from '../components/NavSideBar';

function QrPage() {
    const tl = gsap.timeline();
    const tl2 = gsap.timeline();

    useEffect(() => {

        tl.from(".tempHome__backBg", 1, {
            x: 500,
            ease: "power4.out",
            opacity: 0,
            delay: .3,
            stagger: {
                amount: 0.3
            }
        }).from(".tempHome__frontBg", 1.2, {
            y: 500,
            ease: "power4.out",
            opacity: 0,
            delay: -.7,
            stagger: {
                amount: 0.3
            }
        }).to(".tempHome__frontBg", 0, {
            zIndex: 3,
            delay: -0,
        }).from(".tempHome__head", 1, {
            y: -500,
            ease: "power4.out",
            opacity: 0,
            delay: .2,
            stagger: {
                amount: 0.3
            }
        }).from(".tempHome__btnWrapper", 1.3, {
            y: 500,
            ease: "power4.out",
            opacity: 0,
            delay: -1.2,
            stagger: {
                amount: 0.3
            }
        }).to(".tempHome__frontBg", 0, {
            // zIndex: 0,
            delay: -0,
        }).to(".tempHome__arrBoard", .7, {
            x: 444,
            delay: -1,
        }).to(".tempHome__arrBoard", .7, {
            x: 444,
            delay: -1,
        })
    })


    const animDialogue = () => {
        tl2.to(".tempHome__dialogueBox", 0, {
            display: 'block',
            top: "200%",
            opacity: 1,
            delay: -0,
        })
        .to(".tempHome__dialogueBox", 1.3, {
            top: "50%",
            ease: "power4.out",
            delay: -1.2,
            stagger: {
                amount: 0.3
            }
        }).to(".tempHome__dialogueBox", 1, {
            display: 'none',
            opacity: 0,
            top: '0%',
            delay: 1,
        })
    }




    return (
        <>
        <NavSideBar />
        <div className="container">

            <div className="tempHome">
                <img src={backBg} alt="Mountain Background" className="tempHome__backBg" />
                
                <div className="tempHome__head">
                    <img src={logo} alt="logo" className="tempHome__logo" />
                    <p className="tempHome-heading">Mingma David Sherpa</p>
                </div>

                    <div className="tempHome__btnWrapper">
                        <a target="blank" href="https://www.eliteexped.com/mingma-david-sherpa" className="tempHome-btn">
                            <img src={eliteLogo} alt="" className="tempHome__socialLogo" />
                        </a>
                        <a target="blank" href="https://www.youtube.com/c/MingmaDavidsherpa7" className="tempHome-btn">
                            <img src={ytLogo} alt="" className="tempHome__socialLogo" />
                        </a>
                        <a target="blank" href="https://www.instagram.com/mingma_david_sherpa/" className="tempHome-btn">
                            <img src={igLogo} alt="" className="tempHome__socialLogo" />
                        </a>
                        <a target="blank" href="https://www.facebook.com/mingmadavidsherpa" className="tempHome-btn">
                            <img src={fbLogo} alt="" className="tempHome__socialLogo" />
                        </a>
                        <a target="blank" href="https://twitter.com/mingma_david" className="tempHome-btn">
                            <img src={twLogo} alt="" className="tempHome__socialLogo" />
                        </a>
                        <a target="blank" href="https://www.instagram.com/14peakfoundation/" className="tempHome-btn">
                            <img src={peakLogo} alt="" className="tempHome__socialLogo" />
                        </a>
                    </div>

                <img src={frontBg} alt="Mountain Background" className="tempHome__frontBg" />
                
                <Snowfall  style={{ position: 'fixed' }} snowflakeCount={700} color= {'#fff'} radius={[1.5,5]} speed={[0.5,7]}/>

                <div className="tempHome__arrBoard" onClick= {animDialogue}>
                    <div className="tempHome__arrBoard-wrapper">
                        <img src={arrBoard} alt="Arrow Board" className="tempHome__arrBoardImg" />
                    </div>
                </div>
                <div className="tempHome__dialogueBox">
                    <img src={dialogueImg} alt="Dialogue" className="tempHome__dialogueImg" />
                </div>
                <div className="tempHome__pwredBy">
                    <span className="pwredBy-reg">Powered by &nbsp;</span>
                    <span className="pwredBy-bold--wh">Yeti &nbsp;</span>
                    <span className="pwredBy-bold--col">Tech</span>
                </div>
            </div>
        </div>
        </>
    )
}

export default QrPage
