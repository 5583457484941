import { Box, Button, Center, Flex, Grid, GridItem, Heading, Text } from '@chakra-ui/react'
import React from 'react'
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';

export default function PurchaseCompleted({checkOutInfoPersist,totalPriceInludingTax,deliveryLocation,shippingAmount}) {
    let history = useNavigate();
    return (
        <Box
                color='white'
                pt='80px'
                px='1rem'
                fontSize='2rem'
                bg='black'
                h='100vh'
            >
                <Flex
                    direction='column'
                    p='2rem'
                    maxW='70rem'
                    mx='auto'
                    mb='5rem'
                    gap='3rem'
                >
                    <Text fontSize='5xl'>Purchase Details</Text>
                    <Box
                        h='40rem'
                        overflowY='auto'
                    >
                        {checkOutInfoPersist && checkOutInfoPersist.map((item, index) => {
                            return (
                                <Grid templateColumns='repeat(2,1fr)' borderBottom='1px solid #343434' p='2rem'>
                                    <GridItem>
                                        {item.name}
                                    </GridItem>
                                    <GridItem textAlign='right'>
                                        Rs. {item.price}
                                    </GridItem>
                                    <GridItem display='flex' gap='15px'>
                                        <Box>
                                            Color:{item.color}
                                        </Box>
                                        <Box>
                                            Size: {item.size}
                                        </Box>
                                    </GridItem>
                                    <GridItem textAlign='right'>
                                        QTY {item.quantity} Pcs
                                    </GridItem>
                                </Grid>
                            )
                        })}
                    </Box>

                    <Grid templateColumns='repeat(2,1fr)' borderTop='1px solid #343434' py='2rem' gap='1rem'>
                        <GridItem>
                            <Text color={'gray'} fontSize={'xl'}>{deliveryLocation}</Text>
                            Shipping Charge
                        </GridItem>
                        <GridItem textAlign='right'>
                            {shippingAmount === 0 ? 'Pick Up': `Rs. ${shippingAmount}`}
                            
                        </GridItem>
                        <GridItem>
                            Total Amount
                        </GridItem>
                        <GridItem textAlign='right'>
                            Rs. {totalPriceInludingTax ? totalPriceInludingTax.totalPrice + shippingAmount : '0'}
                        </GridItem>
                    </Grid>

                   <Button
                       w='40rem'
                       bg='#EAA13F'
                       fontSize='3xl'
                       fontWeight='none'
                       color='white'
                       py='3rem'
                       border='none'
                       borderRadius='1.4rem'
                       _hover={{ cursor: 'pointer' }}
                       onClick={()=>history("/user/myOrders/")}
                       pos="absolute"
                       bottom={'2rem'}
                       left={0}
                       right={0}
                       margin={'auto'}
                   >
                       Check Orders
                   </Button>
                </Flex>
        </Box>
    )
}
