import React from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { checkOutInfoPersist } from "../../redux/ecommerceSlice";

export const PickASize = ({
  value,
  specificSizeArray,
  specificIndx,
  formik,
  setProduct_extraId,
  setquantity,
}) => {
  const dispatch = useDispatch();
  const checkOutInfo = useSelector(
    (state) => state.ecommerce.checkOutInfoPersist
  );
  // console.log("checkOutInfocheckOutInfocheckOutInfo", checkOutInfo);
  // console.log("checkOutInfocheckOutInfocheckOutInfo", value);
  // doing this as "0" value is registered as false value
  specificIndx = specificIndx + 1;
  // console.log("formik.values.size ", formik.values.size);
  let specificSize;

  // used for updating the size , after adding the product in cart
  function changeSize() {
    let checkOutIndex;
    // console.log("niceeeeeeeeee");
    if (
      checkOutInfo?.some((curr, index) => {
        checkOutIndex = index;
        return curr.product === value?.id;
      })
    ) {
      let updateSize = checkOutInfo?.map((curr, indx) => {
        // console.log("checkOutIndex", checkOutIndex);
        if (indx === checkOutIndex) {
          // console.log("specificSize", specificSize);
          return { ...curr, size: specificSize };
        }
        return curr;
      });
      // console.log("a", updateSize);
      dispatch(checkOutInfoPersist(updateSize));
    }
  }

  function changeSizeOfAllProducts() {
    let checkOutIndex;
    // console.log("niceeeeeeeeee");
    if (
      checkOutInfo?.some((curr, index) => {
        checkOutIndex = index;
        return curr.product === value?.id;
      })
    ) {
      let updateSize = checkOutInfo?.map((curr, indx) => {
        // console.log("checkOutIndex", checkOutIndex);
        if (indx === checkOutIndex) {
          // console.log("specificSize", specificSize);
          return { ...curr, size: specificSize };
        }
        return curr;
      });
      // console.log("a", updateSize);
      dispatch(checkOutInfoPersist(updateSize));
    }
  }
  return (
    <div>
      {specificIndx ? (
        <p className="merchTot-title">PICK A SIZE (US Size)</p>
      ) : (
        ""
      )}

      <div className="merchTot__sizes">
        {specificSizeArray?.map((curr) => {
          // console.log("curr from size", curr);
          if (curr.product_inventory !== 0) {
            return (
              <p
                className={
                  formik.values.size === curr.size
                    ? "merchTot__size-selected"
                    : "merchTot__size"
                }
                key={curr.id}
                onClick={() => {
                  formik.setFieldValue("product_extra", curr.id);
                  formik.setFieldValue("size", curr.size);
                  formik.setFieldValue("product_inventory", {
                    current: curr.product_inventory,
                  });
                  specificSize = curr.size;
                  setProduct_extraId(curr.id);

                  changeSize();
                  // setquantity(1);
                }}
              >
                {curr.size}
              </p>
            );
          }
        })}
      </div>
    </div>
  );
};
