import { Box, Button, Center, Flex, Grid, GridItem, Text } from '@chakra-ui/react'
import React from 'react'
import { useState } from 'react'
import { createStandaloneToast } from "@chakra-ui/react";
import NavSideBar from '../../components/NavSideBar';

export default function PaymentSelection({ khaltiPaymentOnClickHandeler, cashOnDeliveryOnClickHandeler }) {

    const paymentMedium = ['KHALTI', 'CASH']

    const [currentPaymentMedium, setCurrentPaymentMedium] = useState('')
    const toast = createStandaloneToast();

    function handlePayment() {
        if (currentPaymentMedium === '') {
            toast({
                title: "Please select payment medium",
                status: "warning",
                duration: 3000,
                isClosable: true,
                position: "top",
            });
            return
        } else {
            if (currentPaymentMedium === 'KHALTI') {
                khaltiPaymentOnClickHandeler();
            }
            if (currentPaymentMedium === 'CASH') {
                cashOnDeliveryOnClickHandeler();
            }
        }

    }

    return (
        <>
            <NavSideBar />
            <Box
                color='white'
                pt='80px'
                px='1rem'
                fontSize='2rem'
                bg='black'
                h='100vh'
            >
                <Flex
                    direction='column'
                    p='2rem'
                    maxW='70rem'
                    mx='auto'
                    mb='5rem'
                    gap='3rem'
                    h='80rem'
                    pos='relative'
                >

                    <Text fontSize='5xl'>Select Payment</Text>

                    <Grid templateColumns='repeat(2,1fr)' gap='3rem' mb={'30rem'} mt='3rem'>

                        {paymentMedium.map((value, index) => {
                            return (
                                <GridItem key={index}>
                                    <Flex
                                        justify='center'
                                        p='1rem'
                                        align='center'
                                        h='11rem'
                                        maxW='100%'
                                        borderRadius='1.4rem'
                                        textAlign='center'
                                        cursor="pointer"
                                        bg={currentPaymentMedium === value ? "#EAA13F" : "#2B2A2A"}
                                        onClick={() => setCurrentPaymentMedium(value)}
                                    >
                                        {value}
                                    </Flex>
                                </GridItem>
                            )
                        })}
                    </Grid>

                <Button
                    w='40rem'
                    bg='#EAA13F'
                    fontSize='4xl'
                    fontWeight='none'
                    color='white'
                    py='3rem'
                    border='none'
                    borderRadius='1.4rem'
                    _hover={{ cursor: 'pointer' }}
                    onClick={handlePayment}
                    pos='absolute'
                    bottom='0'
                    left={0}
                    right={0}
                    margin={'auto'}
                >
                    Make Payment
                </Button>
                </Flex>
            </Box>
        </>
    )
}
