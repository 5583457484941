import React, { useEffect, useState } from "react";
import { Box, Button, Center, Grid, GridItem, Input } from "@chakra-ui/react";
import { EditIcon } from "@chakra-ui/icons";
import { Flex, HStack } from "@chakra-ui/react";
import { useFormik } from "formik";
import MapProfile from "../../components/MapProfile";
import { useDispatch, useSelector } from "react-redux";
import { updateUserProfile, userProfile } from "../../redux/ecommerceSlice";
import Account from "../../assets/img/icons/account.png";
import { EmailChange } from "./Component/EmailChange";
import { PasswordChange } from "./Component/PasswordChange";

export const Form = () => {
  const [lat, setLat] = useState(27.7172);
  const [lng, setLng] = useState(85.324);
  const [addr, setAddr] = useState("Pin Your Location");
  const profileData = useSelector((state) => state.ecommerce.userProfile);

  const [file, setFile] = React.useState();

  const dispatch = useDispatch();

  const updateProfile = (full_name, phone) => {
    dispatch(updateUserProfile({ full_name, phone }));

    dispatch(updateUserProfile({ full_name, phone, file: file ? file : null }));
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      full_name: profileData?.full_name,
      phone: profileData?.phone,
    },
    onSubmit: (values) => {
      updateProfile(values.full_name, values.phone);
    },
  });

  function handleUpload(event) {
    setFile(event.target.files[0]);
  }

  return (
    <Box fontSize={"1.5rem"} mt={5}>
      <HStack>
        <Box position={"relative"}>
          {/* ui chakra "Image" component is not working for borderRadius so using "img" */}
          <Input
            accept="image/png, image/jpeg"
            id="upfile"
            display={"none"}
            type="file"
            onChange={handleUpload}
          />
          <img
            src={
              file
                ? URL.createObjectURL(file)
                : profileData?.profile
                ? profileData?.profile
                : Account
            }
            onClick={() => document.getElementById("upfile").click()}
            style={{
              width: "11rem",
              height: "11rem",
              cursor: "pointer",
              borderRadius: "100%",
              objectFit: "cover",
            }}
            alt="profile img"
          />
          <Center
            w="2rem"
            h="2rem"
            bg="#F7FAFC"
            position={"absolute"}
            top="70%"
            left="80%"
            borderRadius="full"
            p={"2px"}
            cursor={"pointer"}
            onClick={() => document.getElementById("upfile").click()}
          >
            {/* <VscEdit size={10} /> */}
            <EditIcon w="7" />
          </Center>
        </Box>
        <Flex flexDirection={"column"}>
          <Box color={"#F7FAFC"} fontSize="2rem" fontWeight={"bold"} ml="1rem">
            {profileData?.full_name}
          </Box>
          <Box color="#F7FAFC" fontWeight={"400"} ml="1rem" fontSize={"1.1rem"}>
            {profileData?.email}
          </Box>
        </Flex>
      </HStack>
      <Grid templateColumns="repeat(4, 1fr)" columnGap="10rem">
        <GridItem colSpan={{ base: 2, md: 2, sm: 4 }}>
          <Grid templateColumns="repeat(2, 1fr)" gap={4}>
            <form onSubmit={formik.handleSubmit}>
              <GridItem colSpan={2}>
                <Box color={"#F7FAFC"} mr="20%" mt="1.5%">
                  User Name
                </Box>
                <Box mt="4">
                  <Input
                    type={"text"}
                    height="4rem"
                    bg="black"
                    border={"none"}
                    id="full_name"
                    onChange={formik.handleChange}
                    value={formik.values.full_name}
                    color={"white"}
                  />
                </Box>
              </GridItem>
              <GridItem colSpan={{ base: 2, md: 2, sm: 4 }}>
                <Box color={"#F7FAFC"} mr="20%" mt="1.5%">
                  Phone Number
                </Box>
                <Box mt="4">
                  <Input
                    type={"number"}
                    height="4rem"
                    bg="black"
                    border={"none"}
                    id="phone"
                    onChange={formik.handleChange}
                    value={formik.values.phone}
                    color={"white"}
                  />
                </Box>
              </GridItem>

              <GridItem>
                <Button
                  color={"#F7FAFC"}
                  colorScheme="orange"
                  w="15rem"
                  h="3rem"
                  type="submit"
                  mt={4}
                  border={"none"}
                  cursor={"pointer"}
                  fontSize="1.5rem"
                >
                  Save Changes
                </Button>
              </GridItem>
            </form>

            <GridItem colSpan={{ base: 2, md: 2, sm: 4 }}>
              <Box>
                <EmailChange profileData={profileData} />
              </Box>
              <Box>
                <PasswordChange />
              </Box>
            </GridItem>
          </Grid>
        </GridItem>

        <GridItem colSpan={{ base: 2, md: 2, sm: 4 }}>
          <Box color={"#F7FAFC"} mr="20%" mt="1.5%">
            Choose your shipping location.
          </Box>
          <Input
            type={"text"}
            height="4rem"
            mt={"4"}
            bg="black"
            border={"none"}
            id="address"
            onChange={formik.handleChange}
            value={addr}
            color={"white"}
          />
          <Box mt="3%" width={"100%"}>
            <MapProfile
              profileData={profileData}
              setAddr={setAddr}
              lat={lat}
              lng={lng}
              setLat={setLat}
              setLng={setLng}
              savelocation={true}
            />
          </Box>
        </GridItem>
      </Grid>
    </Box>
  );
};
